import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(makerId: number, queryParams: string) {
    const path = `/api/internal/shops?maker_id=${makerId}&${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  inactive(makerId: number, queryParams: string) {
    const path = `/api/internal/shops/inactive?maker_id=${makerId}&${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  create(data: Partial<Shop>) {
    const path = '/api/internal/shops';

    return api({
      method: 'post',
      url: path,
      data,
      headers,
    });
  },
  update(shopId: number, data: Partial<Shop>) {
    const path = `/api/internal/shops/${shopId}`;

    return api({
      method: 'patch',
      url: path,
      data,
      headers,
    });
  },
  get(shopId: number) {
    const path = `/api/internal/shops/${shopId}`;

    return api({
      method: 'get',
      url: path,
    });
  },
  validateRut(rut: string, shopId?: number) {
    let path = `/api/internal/shops/validate_rut?rut=${rut}`;
    if (!!shopId) {
      path = `${path}&shop_id=${shopId}`;
    }

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  lateOnPayments(shopId: number) {
    const path = `/api/internal/shops/${shopId}/late_on_payments`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
};
