<template>
  <div
    v-if="subOrder.shipmentMethod === 'shop_pickup'"
    class="flex flex-col px-5 h-full text-gray-700"
  >
    <DialogTitle class="mb-4 font-serif text-xl text-center text-gray-700 md:text-2xl">
      Confirma este pedido
    </DialogTitle>
    <p class="text-sm font-light md:text-base">
      El cliente ha seleccionado la opción de retiro en tienda para este pedido. En caso de que
      tengas los productos en stock solo debes confirmar el pedido y esperar que coordinemos
      el retiro de los productos.
    </p>
    <lokal-loading
      v-if="isLoading"
      :loading="isLoading"
      class="self-center mt-auto"
    />
    <button
      v-else
      class="p-2 mt-auto text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
      @click="subOrderMutation.mutate()"
    >
      Confirmar
    </button>
  </div>
  <template v-else-if="!showShipmentForm && !showMakerShipmentForm">
    <DialogTitle class="mb-1 font-serif text-xl text-center text-gray-700 md:text-2xl">
      Selecciona un método de envío
    </DialogTitle>
    <div
      v-if="!showShipmentForm && !showMakerShipmentForm"
      class="flex flex-col px-5 h-full"
    >
      <DialogDescription
        class="mb-4 text-sm font-light text-center text-gray-700 md:text-base"
      >
        Para confirmar el pedido primero debes seleccionar un método de envío.
      </DialogDescription>
      <button
        class="flex items-center p-2 space-x-2 text-sm text-lk-green rounded-md border border-lk-green md:space-x-3 md:text-base"
        :class="{ 'bg-lk-green text-white': selectedOption === 'lokalShipping',
                  'mb-3': selectedOption !== 'lokalShipping' }"
        @click="selectedOption = 'lokalShipping'"
      >
        <div
          class="flex shrink-0 justify-center items-center w-5 h-5 bg-white rounded-full border-2 md:w-6 md:h-6"
          :class="{ 'border-white': selectedOption === 'lokalShipping',
                    'border-lk-green': selectedOption !== 'lokalShipping' }"
        >
          <div
            class="shrink-0 w-2 h-2 rounded-full md:w-3 md:h-3"
            :class="{ 'bg-lk-green': selectedOption === 'lokalShipping',
                      'bg-white': selectedOption !== 'lokalShipping' }"
          />
        </div>
        <p class="flex items-center">
          <inline-svg-wrapper
            v-if="selectedOption === 'lokalShipping'"
            class="shrink-0 mr-2 w-5 h-5 text-white md:w-6 md:h-6"
            name="outline-lokal-truck-with-white-k"
          />
          <inline-svg-wrapper
            v-else
            class="shrink-0 mr-2 w-5 h-5 text-lk-green md:w-6 md:h-6"
            name="outline-lokal-truck-with-green-k"
          />
          <span>Realizar envío con Lokal</span>
        </p>
      </button>
      <p
        v-if="selectedOption === 'lokalShipping'"
        class="px-2 mt-1 mb-2 text-sm font-light"
      >
        *Con esta opción, te enviaremos una etiqueta del carrier que
        escojas para que la imprimas y pegues en la caja de este pedido.
      </p>
      <button
        class="flex items-center p-2 space-x-2 text-sm text-lk-green rounded-md border border-lk-green md:space-x-3 md:text-base"
        :class="{ 'bg-lk-green text-white': selectedOption === 'makerShipping' }"
        @click="selectedOption = 'makerShipping'"
      >
        <div
          class="flex shrink-0 justify-center items-center w-5 h-5 bg-white rounded-full border-2 md:w-6 md:h-6"
          :class="{ 'border-white': selectedOption === 'makerShipping',
                    'border-lk-green': selectedOption !== 'makerShipping' }"
        >
          <div
            class="shrink-0 w-2 h-2 rounded-full md:w-3 md:h-3"
            :class="{ 'bg-lk-green': selectedOption === 'makerShipping',
                      'bg-white': selectedOption !== 'makerShipping' }"
          />
        </div>
        <p class="flex items-center text-left">
          <TruckIcon
            class="shrink-0 mr-2 w-5 h-5 md:w-6 md:h-6"
            :class="{ 'text-white': selectedOption === 'makerShipping',
                      'text-lk-light-purple': selectedOption !== 'makerShipping' }"
          />
          <span>Realizar envío por mi cuenta</span>
        </p>
      </button>
      <p
        v-if="selectedOption === 'makerShipping'"
        class="px-2 mt-1 mb-2 text-sm font-light"
      >
        *Con esta opción, ustedes ({{ maker.name }}) deberán realizar el envío del pedido
        a la tienda.
      </p>
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
        class="self-center mt-auto"
      />
      <button
        v-else
        class="p-2 mt-auto text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
        @click="subOrderMutation.mutate()"
      >
        {{ selectedOption === 'makerShipping' ? 'Confirmar' : 'Confirmar y continuar con los detalles' }}
      </button>
    </div>
  </template>
</template>
<script lang="ts" setup>
import { TruckIcon } from '@heroicons/vue/24/outline';
import { ref, computed } from 'vue';
import subOrdersApi from 'api/sub-orders';
import { useMutation } from 'vue-query';
import {
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

interface SubOrderShipmentDialogProps {
  subOrder: SubOrder,
  maker: Maker,
  showMakerShipmentForm: boolean,
  showShipmentForm: boolean,
}
const props = withDefaults(defineProps<SubOrderShipmentDialogProps>(), {});

const emit = defineEmits<{(e: 'update:isOpen', value: boolean): void,
  (e: 'update:subOrder', value: SubOrder): void,
  (e: 'update:showMakerShipmentForm', value: boolean): void,
  (e: 'update:showShipmentForm', value: boolean): void,
}>();

// when the subOrder is picked-up by the shop, the shipmentMethod is already selected
const selectedOption = ref(props.subOrder.shipmentMethod || 'lokalShipping');
const shipmentMethod = computed(() => {
  if (selectedOption.value === 'lokalShipping') return 'lokal';
  else if (selectedOption.value === 'makerShipping') return 'maker';

  return 'shop_pickup';
});

const subOrderMutation = useMutation(() =>
  subOrdersApi.confirm(props.subOrder.id, { shipmentMethod: shipmentMethod.value }), {
  onSuccess: (data) => {
    emit('update:subOrder', data.data.subOrder);
    if (shipmentMethod.value === 'lokal') {
      emit('update:showShipmentForm', true);
    } else if (shipmentMethod.value === 'maker') {
      emit('update:showMakerShipmentForm', true);
    } else {
      emit('update:isOpen', false);
    }
  },
});
const isLoading = computed(() => subOrderMutation.isLoading.value);
</script>
