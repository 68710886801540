<template>
  <div class="flex flex-col items-center mb-10 w-full h-full text-gray-700 md:px-5 lg:px-10 xl:max-w-7xl">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Usuarios</span>
      </p>
    </div>
    <div class="px-2 w-full md:px-0">
      <button
        v-if="showWarning"
        class="flex flex-col items-center p-3 mb-3 w-full text-sm text-left text-white bg-red-500 rounded-md sm:flex-row sm:text-base"
        @click="emit('update-private-information')"
      >
        <ExclamationCircleIcon class="shrink-0 mr-2 w-7 h-7" />
        <span>Debes completar la información de "nombre de contacto", "email de contacto" y "teléfono de contacto"
          antes de modificar las notificaciones.</span>
        <span class="shrink-0 py-1 px-2 text-red-500 bg-white rounded-md sm:py-2 sm:ml-auto">Ir a completar</span>
      </button>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full text-sm bg-white rounded-md sm:text-base md:py-5 md:px-4 lg:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else>
        <maker-user-list-card
          v-for="user, index in users"
          :key="index"
          :user="user"
          :edit-notification-disabled="showWarning"
        />
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import usersApi from 'api/users';
import MakerUserListCard from './maker-user-list-card.vue';

const props = defineProps<{
  maker: Maker
}>();

const { isLoading, data } = useQuery('users', () => usersApi.index(`maker_id=${props.maker.id}`));
const users = computed(() => data.value?.data.users as User[]);

const showWarning = computed(() =>
  !props.maker.contactName || !props.maker.contactEmail || !props.maker.contactNumber);

const emit = defineEmits<{(e: 'update-private-information'): void}>();
</script>
