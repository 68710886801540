import { computed } from 'vue';
import { reduce, delay } from 'lodash';
import useUrlParamStore from 'stores/url-param-store';

const DELAY_TIME = 200;

type QueryParamType = {
  key: string
  value: string[] | number[] | boolean[] | (string | number | boolean)[]
}

function useUrlMethods() {
  function getQueryParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);

    return Object.fromEntries(urlSearchParams.entries());
  }
  const queryParams = computed(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return params;
  });
  const referredParam = computed(() => queryParams.value.referred);

  function useUpdateUrlHistory(params: QueryParamType[]) {
    let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?`;
    const newParams = referredParam.value ? [...params, { key: 'referred', value: [referredParam.value] }] : params;
    newParams.forEach((object, index) => {
      if (object.value.length > 0) {
        newUrl = `${newUrl}${object.key}=${object.value}${index === newParams.length - 1 ? '' : '&'}`;
      }
    });
    window.history.pushState({ path: newUrl }, '', newUrl);
  }

  function updateUrlParam(param: QueryParamType) {
    const previousParams = getQueryParams();
    const newParams = { ...previousParams, [param.key]: param.value };
    const basePath = `${window.location.protocol}//${window.location.host}${window.location.pathname}?`;
    const newUrl = reduce(newParams, (result, value, key) => {
      if (value.length > 0) return `${result}${key}=${value}&`;

      return result;
    }, basePath);
    window.history.pushState({ path: newUrl }, '', newUrl);
  }

  function updateUrlParams(params: { [key: string]: string }) {
    function delayedUpdateUrlParams() {
      const previousParams = getQueryParams();
      const urlParamStore = useUrlParamStore();
      const newParams = { ...previousParams, ...params, ...urlParamStore.paramsToUpdate };
      const basePath = `${window.location.protocol}//${window.location.host}${window.location.pathname}?`;
      const newUrl = reduce(newParams, (result, value, key) => {
        if (value.length > 0) return `${result}${key}=${value}&`;

        return result;
      }, basePath);
      window.history.pushState({ path: newUrl }, '', newUrl);
      urlParamStore.$reset();
    }

    return delay(delayedUpdateUrlParams, DELAY_TIME);
  }

  function getQueryParam(queryKey: string) {
    // https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript?
    // page=1&tab=scoredesc#tab-top
    return queryParams.value[queryKey];
  }

  return { useUpdateUrlHistory, updateUrlParam, updateUrlParams, queryParams, getQueryParam };
}

export default useUrlMethods;
