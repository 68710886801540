export default {
  general: {
    true: 'Sí',
    false: 'No',
  },
  landing: {
    testimonials: {
      whitnessType: {
        maker: 'Marca',
        shop: 'Tienda',
      },
    },
    frequentQuestions: {
      kind: {
        maker: 'Marca',
        shop: 'Tienda',
      },
    },
  },
  order: {
    status: {
      draft: 'borrador',
      pending: 'pendiente',
      financiallyApproved: 'confirmación pendiente',
      confirmed: 'confirmada',
      deliveryInProgress: 'en despacho',
      delivered: 'entregada',
    },
    paymentStatus: {
      created: 'por facturar',
      billed: 'facturada',
      partiallyPaid: 'pago parcial',
      paid: 'pagada',
    },
    returnStatus: {
      noReturn: 'sin devolución',
      returnInProgress: 'devolución en proceso',
      partialReturn: 'devolución parcial',
      fullReturn: 'devolución total',
    },
  },
  subOrder: {
    paymentStatus: {
      paid: 'pagado',
      unpaid: 'pago pendiente',
      underReview: 'en revisión',
    },
    status: {
      draft: 'pendiente',
      pending: 'pendiente',
      confirmed: 'despacho pendiente',
      deliveryInProgress: 'en despacho',
      delivered: 'entregado',
      readyForPickup: 'listo para retiro',
    },
    modificationsStatus: {
      noModifications: 'sin modificaciones',
      inProgress: 'en proceso',
      resolved: 'resuelta',
    },
    shipmentMethod: {
      lokal: 'Lokal',
      maker: 'Marca',
      shopPickup: 'Retiro en tienda',
    },
  },
  shop: {
    kind: {
      physicalStore: 'Tienda física',
      onlineStore: 'Tienda online',
      personalConsumption: 'Consumo personal',
      other: 'Otro',
      physicalAndOnlineStore: 'Tienda física y online',
      user: 'Usuario',
    },
  },
  orderPayment: {
    kind: {
      payment: 'pago de orden',
      delivery: 'despacho',
      return: 'devolución',
      smartPayment: 'pago de orden',
    },
  },
  shopPayment: {
    kind: {
      payment: 'pago de orden',
      delivery: 'despacho',
      return: 'devolución',
      smartPayment: 'pago de orden/es',
    },
  },
  product: {
    status: {
      active: 'activo',
      outOfStock: 'sin stock',
      inactive: 'inactivo',
    },
    shelfLifeUnit: {
      days: 'días',
      months: 'meses',
    },
  },
  billingInformation: {
    businessRut: 'RUT',
    commercialBusiness: 'Giro comercial',
    socialReason: 'Razón social',
    contactEmail: 'Email de contacto',
    addressId: 'Dirección',
  },
  freeShippingInvoice: {
    paymentStatus: {
      unpaid: 'Sin pagar',
      paid: 'Pagado',
    },
  },
};
