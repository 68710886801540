<template>
  <div class="flex w-full max-w-7xl">
    <div
      v-if="menuStore.showMakerFilterSidebar"
      class="fixed inset-0 z-20 bg-gray-800/50"
      aria-hidden="true"
      @click="menuStore.showMakerFilterSidebar = false"
    />
    <transition
      enter-active-class="transition-transform duration-300"
      enter-from-class="-translate-x-full sm:translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition-transform duration-300"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full sm:translate-x-full"
    >
      <aside
        v-if="menuStore.showMakerFilterSidebar || !isMobile"
        id="maker-filter-sidebar"
        class="overflow-y-auto fixed left-0 z-20 w-2/3 h-screen text-sm text-gray-700 bg-gray-50 sm:static sm:left-auto sm:z-0 sm:w-3/5 md:overflow-hidden md:w-80 md:h-auto md:text-base xl:bg-white"
        :class="{ 'border-r border-gray-300': isMobile }"
      >
        <div class="px-2 h-full sm:py-6 sm:mx-5 md:py-10 ">
          <div
            v-if="isMobile"
            class="flex w-full"
          >
            <button
              class="mt-2 ml-auto"
              @click="menuStore.showMakerFilterSidebar = !menuStore.showMakerFilterSidebar"
            >
              <XMarkIcon class="w-5 h-5" />
            </button>
          </div>
          <div class="flex items-center mb-2 text-lg md:text-xl">
            <FunnelIcon
              class="mr-2 w-5 h-5 text-lk-green fill-lk-green md:w-6 md:h-6"
            />
            <p>Filtros</p>
          </div>
          <div class="flex flex-wrap items-center">
            <button
              v-for="record in selectedCategories"
              :key="record.id"
              class="flex items-center p-1 mr-1 mb-1 text-sm text-white bg-lk-green rounded-md"
              @click="removeSelectedCategory(record)"
            >
              {{ record.name }}
              <XCircleIcon class="w-4 h-4 text-lk-green cursor-pointer fill-white" />
            </button>
            <button
              v-for="label in selectedLabels"
              :key="label.id"
              class="flex items-center p-1 mr-1 mb-1 text-sm text-white bg-lk-green rounded-md"
              @click="removeSelectedLabel(label)"
            >
              {{ label.name }}
              <XCircleIcon class="w-4 h-4 text-lk-green cursor-pointer fill-white" />
            </button>
          </div>
          <div class="mb-3 w-full border-b" />
          <button
            v-if="sessionStore.user?.shopId"
            class="flex flex-col p-3 mb-2 w-full text-left bg-white rounded-md border md:bg-gray-50"
            :class="{ 'border-lk-green': showOnlyMakersWithFreeShippingToCurrentAddress }"
            type="button"
            @click="showOnlyMakersWithFreeShippingToCurrentAddress = !showOnlyMakersWithFreeShippingToCurrentAddress"
          >
            <div class="flex items-center w-full">
              <TruckIcon
                class="shrink-0 w-5 h-5 md:w-6 md:h-6"
              />
              <lokal-switch
                v-model="showOnlyMakersWithFreeShippingToCurrentAddress"
                class="ml-auto"
              />
            </div>
            <p>Despacho gratis a tu dirección</p>
          </button>
          <button
            class="flex flex-col p-3 mb-2 w-full text-left bg-white rounded-md border md:bg-gray-50"
            :class="{ 'border-lk-green': showOnlyMakersWithSubsidizedShipping }"
            type="button"
            @click="showOnlyMakersWithSubsidizedShipping = !showOnlyMakersWithSubsidizedShipping"
          >
            <div class="flex items-center w-full">
              <TruckIcon
                class="shrink-0 w-5 h-5 md:w-6 md:h-6"
              />
              <lokal-switch
                v-model="showOnlyMakersWithSubsidizedShipping"
                class="ml-auto"
              />
            </div>
            <p>Despacho con descuento a tu dirección</p>
          </button>
          <button
            class="flex flex-col p-3 mb-2 w-full text-left bg-white rounded-md border md:bg-gray-50"
            :class="{ 'border-lk-green': showOnlyMakersWithDiscount }"
            type="button"
            @click="showOnlyMakersWithDiscount = !showOnlyMakersWithDiscount"
          >
            <div class="flex items-center w-full">
              <TagIcon class="shrink-0 w-5 h-5 md:w-6 md:h-6" />
              <lokal-switch
                v-model="showOnlyMakersWithDiscount"
                class="ml-auto"
              />
            </div>
            <p>Con descuentos</p>
          </button>
          <button
            class="flex flex-col p-3 mb-2 w-full text-left bg-white rounded-md border md:bg-gray-50"
            :class="{ 'border-lk-green': showOnlyMakersWithVolumeDiscount }"
            type="button"
            @click="showOnlyMakersWithVolumeDiscount = !showOnlyMakersWithVolumeDiscount"
          >
            <div class="flex items-center w-full">
              <TagIcon class="shrink-0 w-5 h-5 md:w-6 md:h-6" />
              <lokal-switch
                v-model="showOnlyMakersWithVolumeDiscount"
                class="ml-auto"
              />
            </div>
            <p>Descuentos por volumen</p>
          </button>
          <button
            class="flex items-center"
            @click="showOnlyMakersWithFreeShipping = !showOnlyMakersWithFreeShipping"
          >
            <div
              class="mr-2 w-4 h-4 rounded-md border"
              :class="{ 'bg-lk-green border-0 transition ease-in-out duration-500': showOnlyMakersWithFreeShipping }"
            />
            <p>Despacho gratis</p>
          </button>
          <button
            class="flex items-center mb-2"
            @click="showOnlyNewMakers = !showOnlyNewMakers"
          >
            <div
              class="mr-2 w-4 h-4 rounded-md border"
              :class="{ 'bg-lk-green border-0 transition ease-in-out duration-500': showOnlyNewMakers }"
            />
            <p class="text-left">
              Nuevas incorporaciones
            </p>
          </button>
          <div class="w-full border-b" />
          <p class="my-2 text-base md:text-xl">
            Mínimos por marca
          </p>
          <div class="overflow-auto mb-2">
            <button
              v-for="priceRage, index in minimumPurchaseAmountRage"
              :key="index"
              class="flex items-center"
              @click="selectMinimumPurchaseAmountRage(index)"
            >
              <div
                class="mr-2 w-4 h-4 rounded-md border"
                :class="{ 'bg-lk-green border-0 transition ease-in-out duration-500': priceRage.selected }"
              />
              <div class="self-start hover:text-lk-green">
                <template v-if="priceRage.max > 100000">
                  {{ useFormatCurrency(priceRage.min) }}+
                </template>
                <template v-else>
                  {{ useFormatCurrency(priceRage.min) }} - {{ useFormatCurrency(priceRage.max) }}
                </template>
              </div>
            </button>
          </div>
          <div class="w-full border-b" />
          <p class="my-2 text-base md:text-xl">
            Categorías
          </p>
          <search-bar
            v-model="categorySearchText"
            placeholder="Buscar categoría"
            name="catergorySearchText"
            class="mb-2 bg-white"
            size="sm"
          />
          <div class="flex flex-col mb-2 w-full">
            <button
              v-for="category in filteredCategories"
              :key="category.id"
              class="flex items-center"
              @click="addCategoryToSelecetedCategoryRecords(category)"
            >
              <div
                class="shrink-0 mr-2 w-4 h-4 rounded-md border"
                :class="{
                  'bg-lk-green border-0 transition ease-in-out duration-500': selectedCategoryIds.includes(category.id)
                }"
              />
              <p class="self-start text-left hover:text-lk-green">
                {{ category.name }}
              </p>
            </button>
          </div>
          <div class="w-full border-b" />
          <p class="my-2 text-base md:text-xl">
            Etiquetas de productos
          </p>
          <search-bar
            v-model="labelSearchText"
            placeholder="Buscar etiqueta"
            name="labelSearchText"
            class="mb-2 bg-white"
            size="sm"
          />
          <lokal-loading
            v-if="labelsQueryIsLoading"
            v-model="labelsQueryIsLoading"
            size="small"
          />
          <div
            v-else
            class="overflow-auto mb-2 max-h-72"
          >
            <button
              v-for="label in filteredLabels"
              :key="label.id"
              class="flex items-center"
              @click="addLabelToSelecetedLabelRecords(label)"
            >
              <div
                class="mr-2 w-4 h-4 rounded-md border"
                :class="{
                  'bg-lk-green border-0 transition ease-in-out duration-500': selectedLabelIds.includes(label.id)
                }"
              />
              <p class="self-start text-left hover:text-lk-green">
                {{ label.name }}
              </p>
            </button>
          </div>
          <div class="w-full border-b" />
          <p class="my-2 text-base md:text-xl">
            Rango de precios de productos
          </p>
          <div class="mb-2 h-full">
            <button
              v-for="priceRage, index in priceRangeSelection"
              :key="index"
              class="flex items-center"
              @click="priceRage.selected = !priceRage.selected"
            >
              <div
                class="mr-2 w-4 h-4 rounded-md border"
                :class="{
                  'bg-lk-green border-0 transition ease-in-out duration-500': priceRangeSelection[index].selected
                }"
              />
              <div class="self-start hover:text-lk-green">
                {{ useFormatCurrency(priceRage.min) }} - {{ useFormatCurrency(priceRage.max) }}
              </div>
            </button>
          </div>
        </div>
      </aside>
    </transition>
    <div class="flex flex-col py-6 px-3 w-full max-w-5xl md:p-10 xl:px-0">
      <template
        v-for="message, index in messages"
        :key="message"
      >
        <lokal-banner-message-dialog
          v-if="message[1]"
          v-model="showBanners[index]"
          :show-dialog="message[0].includes('dialog')"
          :color-key="(message[0] as ColorKeyType)"
          :message="message[1]"
          title="Información importante"
          :class="{ 'mb-5': messages[messages.length - 1] === messages[index] }"
        />
      </template>
      <a
        class="flex flex-col items-center p-2 mb-3 text-sm text-white bg-lk-light-green rounded-md md:flex-row md:py-5 md:px-3 md:text-base"
        href="/makers?discunts=true"
      >
        <InformationCircleIcon class="shrink-0 mb-2 w-5 h-5 md:mr-2 md:mb-0 md:w-6 md:h-6" />
        <p class="flex flex-col text-left">
          <span>¡Aprovecha de comprar productos de más de 50 marcas con descuentos!</span>
          <span class="font-light">
            Abastece tu tienda antes del Black Friday.
          </span>
          <span class="font-light">
            Revisa las marcas <span class="underline">acá.</span> Válido hasta el 17/11/2024.
          </span>
        </p>
      </a>
      <p class="flex justify-between items-center mb-4 w-full font-serif text-2xl text-gray-700 md:mb-6 md:text-4xl">
        <span>Marcas</span>
        <span class="text-base">{{ totalResources }}</span>
      </p>
      <div class="flex">
        <button
          v-if="isMobile"
          class="flex items-center p-2 mb-2 w-auto rounded-md border"
          @click="menuStore.showMakerFilterSidebar = !menuStore.showMakerFilterSidebar"
        >
          <FunnelIcon
            class="shrink-0 mr-2 w-4 h-4 fill-lk-green md:w-5 md:h-5"
          />
          <p class="text-sm text-gray-700 md:text-base">
            Filtros
          </p>
        </button>
      </div>
      <search-bar
        v-model="searchText"
        name="searchText"
        class="mb-3 md:mb-5"
        placeholder="Buscar por nombre de marca"
      />
      <div
        v-if="loading"
        class="grid grid-cols-2 gap-x-3 gap-y-5 mb-5 sm:grid-cols-2 md:gap-x-5 md:gap-y-8 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-10 xl:grid-cols-4"
      >
        <maker-card-placeholder
          v-for="_, index in new Array(makersPerPage)"
          :key="`fake-maker-card-${index}`"
          :odd-position="index % 2 === 0"
        />
      </div>
      <div
        v-if="!loading && !referredMakersQuery.isLoading.value"
        class="grid grid-cols-2 gap-x-3 gap-y-5 mb-5 sm:grid-cols-2 md:gap-x-5 md:gap-y-8 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-10 xl:grid-cols-4"
      >
        <template v-if="currentMakers.length > 0 || referredMakers.length > 0">
          <template v-if="referredMakers && referredMakers.length > 0 && currentPage === 1">
            <maker-card
              v-for="referredMaker, index in referredMakers"
              :key="referredMaker.id"
              class="border border-red-500"
              :maker="referredMaker"
              :odd-position="index % 2 === 0"
            />
          </template>
          <maker-card
            v-for="maker, index in currentMakers"
            :key="maker.id"
            :maker="maker"
            :odd-position="(referredMakers?.length + index) % 2 === 0"
          />
        </template>
        <div
          v-else
          class="col-span-1 text-2xl text-gray-700 md:col-span-2 lg:col-span-3 xl:col-span-4"
        >
          Ups no encontramos nada ...
        </div>
      </div>
      <pagination-component
        v-if="currentMakers?.length > 0"
        :class="{ 'justify-end': loading }"
        :total-pages="totalPages"
        :current-page="currentPage"
        @set-new-page="updatePage"
      />
      <lokal-loading
        v-else-if="loading"
        :loading="loading"
        size="big"
        class="self-center mb-10"
      />
    </div>
    <shipping-discounts-dialog
      v-if="showShippingDiscountsDialog"
      v-model="showShippingDiscountsDialog"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, watch, inject } from 'vue';
import { sortBy } from 'lodash';
import { XMarkIcon, XCircleIcon, TruckIcon, TagIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { FunnelIcon } from '@heroicons/vue/24/solid';
import useGeneralMenuStore from 'stores/general-menu-store';
import useSessionStore from 'stores/user-store';
import makerCard from './maker-card.vue';
import makerCardPlaceholder from './maker-card-placeholder.vue';
import type { ColorKeyType } from './shared/lokal-banner.vue';
import PaginationComponent from './shared/pagination-component.vue';
import useUrlMethods from './use/url-methods';
import useCustomParseInt from './use/custom-parse-int';
import useFormatCurrency from './use/format-currency';
import LokalSwitch from './shared/lokal-switch.vue';
import shippingDiscountsDialog from './shared/shipping-discounts-dialog.vue';
import useLabelsQuery from './queries/labels-query';
import useActiveMakersQuery from './queries/active-makers-query';
import useReferredMakersQuery from './queries/referred-makers-query';
import searchBar from './shared/search-bar.vue';
import useCleanAccents from './use/clean-accents';

const MAKERS_PER_PAGE = 24;

interface MakerListProps {
  categories: Category[],
  messages?: string[][]
}

const props = withDefaults(defineProps<MakerListProps>(), {
  messages: () => [['', '']],
});

const showShippingDiscountsDialog = ref(false);
const showBanners = ref({} as { [string: number]: boolean });
props.messages.forEach((_, index) => {
  showBanners.value[index] = true;
});

const menuStore = useGeneralMenuStore();
const sessionStore = useSessionStore();
const currentRegionId = computed(() => sessionStore.currentRegionId);
const currentCommuneId = computed(() => sessionStore.currentCommuneId);

const { labels, labelsQuery } = useLabelsQuery(computed(() => ''), computed(() => true));
const labelsQueryIsLoading = computed(() => labelsQuery.isLoading.value);

const { useUpdateUrlHistory, getQueryParam } = useUrlMethods();

const searchText = ref('');
const currentPage = ref(1);
const selectedCategories = ref([] as Category[]);
const selectedLabels = ref([] as Label[]);
const selectedCategoryIds = computed(() => selectedCategories.value.map((category) => category.id));
const selectedLabelIds = computed(() => selectedLabels.value.map((label) => label.id));
const showOnlyNewMakers = ref(false);
const showOnlyMakersWithDiscount = ref(false);
const showOnlyMakersWithVolumeDiscount = ref(false);
const showOnlyMakersWithFreeShipping = ref(false);
const showOnlyMakersWithFreeShippingToCurrentAddress = ref(false);
const showOnlyMakersWithSubsidizedShipping = ref(false);
const freeShippingActive = computed(() => showOnlyMakersWithFreeShipping.value ||
  showOnlyMakersWithFreeShippingToCurrentAddress.value);

const isMobile = inject('isMobile');

const categorySearchText = ref('');
const filteredCategories = computed(() => {
  if (categorySearchText.value.length > 0) {
    return sortBy(props.categories.filter((category) =>
      category.name.toLowerCase().includes(categorySearchText.value.toLowerCase()))
    , (category) => category.priority);
  }

  return sortBy(props.categories, (category) => category.priority);
});

const labelSearchText = ref('');
const filteredLabels = computed(() => {
  if (labelSearchText.value.length > 0) {
    return sortBy(labels.value?.filter((label) =>
      label.name.toLowerCase().includes(labelSearchText.value.toLowerCase()))
    , (label) => label.name);
  }

  return sortBy(labels.value, (label) => label.name);
});

function addCategoryToSelecetedCategoryRecords(category: Category) {
  if (selectedCategoryIds.value.includes(category.id)) {
    selectedCategories.value = selectedCategories.value.filter((record) => record.id !== category.id);
  } else {
    selectedCategories.value.push(category);
  }
}

function addLabelToSelecetedLabelRecords(label: Label) {
  if (selectedLabelIds.value.includes(label.id)) {
    selectedLabels.value = selectedLabels.value.filter((record) => record.id !== label.id);
  } else {
    selectedLabels.value.push(label);
  }
}

// prices
interface PriceRage {
  min: number;
  max: number;
  selected: boolean;
}
const showPrices = ref(false);
const priceRangeSelection = ref([
  { min: 1000, max: 5000, selected: false },
  { min: 5000, max: 10000, selected: false },
  { min: 10000, max: 20000, selected: false },
  { min: 20000, max: 50000, selected: false },
] as PriceRage[]);
const selectedPriceRange = computed(() => priceRangeSelection.value.filter((priceRange) => priceRange.selected));
const completePriceRange = computed(() => {
  const min = Math.min(...selectedPriceRange.value.map((priceRange) => priceRange.min));
  const max = Math.max(...selectedPriceRange.value.map((priceRange) => priceRange.max));

  return [min, max];
});
const selectedPriceRangeQuery = computed(() => {
  let query = '';
  priceRangeSelection.value.forEach((priceRange) => {
    if (priceRange.selected) {
      query += `q[products_wholesale_price_cents_gteq]=${priceRange.min}&`;
      query += `q[products_wholesale_price_cents_lteq]=${priceRange.max}&`;
    }
  });

  return query;
});

const minimumPurchaseAmountRage = ref([
  { min: 0, max: 20000, selected: false },
  { min: 20000, max: 50000, selected: false },
  { min: 50000, max: 100000, selected: false },
  { min: 100000, max: 1000000, selected: false },
] as PriceRage[]);
const selectedMinimumPurchaseAmountRage = computed(() =>
  minimumPurchaseAmountRage.value.find((priceRange) => priceRange.selected));
const completeMinimumPurchaseAmountRange = computed(() => {
  if (selectedMinimumPurchaseAmountRage.value) {
    return [selectedMinimumPurchaseAmountRage.value.min, selectedMinimumPurchaseAmountRage.value.max];
  }

  return [];
});
const minimumPurchaseAmountQuery = computed(() => {
  let base = `q[minimum_purchase_amount_cents_gteq]=${selectedMinimumPurchaseAmountRage.value?.min}&`;
  base = `${base}q[minimum_purchase_amount_cents_lteq]=${selectedMinimumPurchaseAmountRage.value?.max}&`;

  return base;
});

function selectMinimumPurchaseAmountRage(index: number) {
  if (minimumPurchaseAmountRage.value[index].selected) {
    minimumPurchaseAmountRage.value[index].selected = false;
  } else {
    minimumPurchaseAmountRage.value.forEach((priceRange) => {
      priceRange.selected = false;
    });
    minimumPurchaseAmountRage.value[index].selected = true;
  }
}

function addNewMakersQueryDate() {
  const daysAgo = 30;
  const currentDate: Date = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setDate(currentDate.getDate() - daysAgo);
  const formattedCurrentDate = currentDate.toISOString().split('T')[0];
  const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0];

  return `q[created_at_gteq]=${formattedOneMonthAgo}&q[created_at_lteq]=${formattedCurrentDate}&`;
}

function addSelectedCategoryIds() {
  return selectedCategoryIds.value.map((categoryId) => (`q[categories_id_in][]=${categoryId}&`)).join('');
}

function addSelectedLabelIds() {
  return selectedLabelIds.value.map((labelId) => (`q[products_labels_id_in][]=${labelId}&`)).join('');
}

function addFreeShippingsQuery() {
  let subQuery = '';
  if (showOnlyMakersWithFreeShipping.value) subQuery += `q[free_shippings_active_eq]=${true}&`;
  if (showOnlyMakersWithFreeShippingToCurrentAddress.value) {
    if (currentRegionId.value) subQuery += `with_free_shippings_to_region_id=${currentRegionId.value}&`;
    if (currentCommuneId.value) subQuery += `with_free_shippings_to_commune_id=${currentCommuneId.value}&`;
  }
  if (showOnlyMakersWithSubsidizedShipping.value) subQuery += `q[subsidized_shippings_active_eq]=${true}&`;

  return subQuery;
}

function addDiscountQueries() {
  let subQuery = '';
  if (showOnlyMakersWithDiscount.value) {
    const now = new Date().toISOString();
    subQuery += `q[products_discounts_start_date_lteq]=${now}&q[products_discounts_end_date_gteq]=${now}&`;
  }
  if (showOnlyMakersWithVolumeDiscount.value) subQuery += 'q[volume_discounts_active_eq]=true&';

  return subQuery;
}

// eslint-disable-next-line complexity
const queryParams = computed(() => {
  let query = '';
  if (searchText.value) query += `q[name_cont]=${encodeURIComponent(useCleanAccents(searchText.value))}&`;
  if (selectedCategoryIds.value.length) query += addSelectedCategoryIds();
  if (selectedLabelIds.value.length) query += addSelectedLabelIds();
  if (selectedPriceRangeQuery.value) query += selectedPriceRangeQuery.value;
  if (showOnlyNewMakers.value) query += addNewMakersQueryDate();
  query += addDiscountQueries();
  if (freeShippingActive.value || showOnlyMakersWithSubsidizedShipping.value) query += addFreeShippingsQuery();
  if (selectedMinimumPurchaseAmountRage.value) query += minimumPurchaseAmountQuery.value;

  return new URLSearchParams(query).toString();
});

const makersPerPage = computed(() => MAKERS_PER_PAGE);
const { makers, makersQuery } = useActiveMakersQuery(currentPage, queryParams, computed(() => true), makersPerPage);
const totalResources = computed(() => useCustomParseInt(makersQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() => useCustomParseInt(makersQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));
const loading = computed(() => makersQuery.isLoading.value || makersQuery.isPreviousData.value);

const shopId = computed(() => sessionStore.user?.shopId);
const shopEnabled = computed(() => !!shopId.value);
const { referredMakers, referredMakersQuery } = useReferredMakersQuery(shopId, queryParams, shopEnabled);
const referredMakerIds = computed(() => referredMakers.value?.map((maker) => maker.id));
const currentMakers = computed(() => makers.value?.filter((maker) => !referredMakerIds.value?.includes(maker.id)));

function getShippingsParams() {
  return [
    { key: 'free_shipping', value: showOnlyMakersWithFreeShipping.value ? [showOnlyMakersWithFreeShipping.value] : [] },
    { key: 'free_shipping_to_address', value: showOnlyMakersWithFreeShippingToCurrentAddress.value ? [true] : [] },
    { key: 'subsidized_shipping', value: showOnlyMakersWithSubsidizedShipping.value ? [true] : [] },
  ];
}

function updateQueryParams() {
  useUpdateUrlHistory([
    { key: 'category_ids', value: selectedCategoryIds.value },
    { key: 'label_ids', value: selectedLabelIds.value },
    { key: 'product_prices', value: selectedPriceRange.value.length > 0 ? completePriceRange.value : [] },
    { key: 'minimum_purchase_amount', value: completeMinimumPurchaseAmountRange.value },
    { key: 'new', value: showOnlyNewMakers.value ? [showOnlyNewMakers.value] : [] },
    { key: 'discounts', value: showOnlyMakersWithDiscount.value ? [showOnlyMakersWithDiscount.value] : [] },
    ...getShippingsParams(),
    { key: 'page', value: [currentPage.value] },
  ]);
}

function updatePage(newPage: number) {
  currentPage.value = newPage;
}

function setSelectedCategoriesFromQueryParam() {
  const categoryIds = getQueryParam('category_ids');
  if (props.categories?.length && !!categoryIds && categoryIds.length > 0) {
    selectedCategories.value = props.categories.filter((category) => categoryIds.includes(`${category.id}`));
  }
}

function setSelectedLabelsFromQueryParam() {
  const labelIds = getQueryParam('label_ids');
  if (labels.value?.length && !!labelIds && labelIds.length > 0) {
    selectedLabels.value = labels.value?.filter((subCategory) => labelIds.includes(`${subCategory.id}`));
  }
}

function setSelectedPriceRangeFromQueryParam() {
  const newPriceRange = getQueryParam('product_prices');
  if (newPriceRange && newPriceRange.length > 0) {
    const [min, max] = newPriceRange.split(',');
    showPrices.value = true;
    priceRangeSelection.value.forEach((priceRange) => {
      if (priceRange.min === useCustomParseInt(min) || priceRange.max === useCustomParseInt(max)) {
        priceRange.selected = true;
      }
    });
  }
}

function setSelectedMinimumPurchaseAmountFromQueryParams() {
  const minimumPurchaseAmount = getQueryParam('minimum_purchase_amount');
  if (minimumPurchaseAmount && minimumPurchaseAmount.length > 0) {
    const [min, max] = minimumPurchaseAmount.split(',');
    minimumPurchaseAmountRage.value.forEach((priceRange) => {
      if (priceRange.min === useCustomParseInt(min) || priceRange.max === useCustomParseInt(max)) {
        priceRange.selected = true;
      }
    });
  }
}

function setShowOnlyNewMakersFromQueryParam() {
  const newMakers = getQueryParam('new');
  if (newMakers && newMakers.length > 0) {
    showOnlyNewMakers.value = true;
  }
}

function setShowOnlyMakersWithDiscountFromQueryParam() {
  const makersWithDiscount = getQueryParam('discounts');
  if (makersWithDiscount && makersWithDiscount.length > 0) {
    showOnlyMakersWithDiscount.value = true;
  }
}

function setShowOnlyMakersWithFreeShippingFromQueryParam() {
  const makersWithFreeShipping = getQueryParam('free_shipping');
  if (makersWithFreeShipping && makersWithFreeShipping.length > 0) {
    showOnlyMakersWithFreeShipping.value = true;
  }
  const makersWithFreeShippingToAddress = getQueryParam('free_shipping_to_address');
  if (makersWithFreeShippingToAddress && makersWithFreeShippingToAddress.length > 0) {
    showOnlyMakersWithFreeShippingToCurrentAddress.value = true;
  }
}

function setShowOnlyMakersWithSubsidizedShippingFromQueryParam() {
  const makersWithSubsidizedShipping = getQueryParam('subsidized_shipping');
  if (makersWithSubsidizedShipping && makersWithSubsidizedShipping.length > 0) {
    showOnlyMakersWithSubsidizedShipping.value = true;
  }
}

function setPageFromQueryParam() {
  const page = getQueryParam('page');
  if (page) currentPage.value = useCustomParseInt(page);
}

onMounted(() => {
  setSelectedCategoriesFromQueryParam();
  setSelectedLabelsFromQueryParam();
  setSelectedPriceRangeFromQueryParam();
  setSelectedMinimumPurchaseAmountFromQueryParams();
  setShowOnlyNewMakersFromQueryParam();
  setShowOnlyMakersWithDiscountFromQueryParam();
  setShowOnlyMakersWithFreeShippingFromQueryParam();
  setShowOnlyMakersWithSubsidizedShippingFromQueryParam();
  setPageFromQueryParam();
});

watch(selectedCategoryIds, updateQueryParams);
watch(selectedLabelIds, updateQueryParams);
watch(showOnlyNewMakers, updateQueryParams);
watch(showOnlyMakersWithDiscount, updateQueryParams);
watch(showOnlyMakersWithFreeShipping, updateQueryParams);
watch(showOnlyMakersWithFreeShippingToCurrentAddress, updateQueryParams);
watch(showOnlyMakersWithSubsidizedShipping, updateQueryParams);

watch(searchText, updateQueryParams);

watch(currentMakers, (newCurrentMakers) => {
  if (!!newCurrentMakers && newCurrentMakers.length === 0) {
    currentPage.value = 1;
  }
});

watch(currentPage, updateQueryParams);
watch(completePriceRange, updateQueryParams);
watch(selectedMinimumPurchaseAmountRage, updateQueryParams);

function removeSelectedCategory(category: Category) {
  selectedCategories.value = selectedCategories.value.filter((record) => record.id !== category.id);
}

function removeSelectedLabel(label: Label) {
  selectedLabels.value = selectedLabels.value.filter((record) => record.id !== label.id);
}
</script>
