<template>
  <div class="flex flex-col w-full">
    <drag-drop
      v-if="maxNumberOfFiles && Object.keys(files).length === (maxNumberOfFiles - 1)"
      class="z-10 mb-5"
      :uppy="uppy"
      :props="{
        theme: 'light',
        height: '200px'
      }"
    />
    <div
      v-for="file in files"
      :key="file.id"
      class="flex items-center mb-2 space-x-2 text-gray-700"
    >
      <file-uploader-new-file
        :file="file"
        :uppy="uppy"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Uppy, { type UppyFile } from '@uppy/core';
import { DragDrop } from '@uppy/vue';
import XHRUpload from '@uppy/xhr-upload';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { ref, computed, onBeforeUnmount } from 'vue';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import fileUploaderNewFile from './file-uploader-new-file.vue';

interface FileUploaderProps {
  maxNumberOfFiles?: number | null;
  fileType?: string[] | null;
}

const props = withDefaults(defineProps<FileUploaderProps>(), {
  maxNumberOfFiles: null,
  fileType: null,
});

const emit = defineEmits<{(e: 'update-files', value: { priority: number, imageData: ImageData }[]): void,
  (e: 'update-images'): void, (e: 'update-file-paths', value: { [key: string]: string}): void
}>();

const uppy = new Uppy({
  restrictions: {
    maxFileSize: 5000000, maxNumberOfFiles: props.maxNumberOfFiles, allowedFileTypes: props.fileType,
  },
  autoProceed: true,
})
  .use(ThumbnailGenerator, { thumbnailWidth: 200 })
  .use(XHRUpload, { endpoint: '/upload' });

const files = ref({} as { [key: string]: UppyFile});
const filesPriority = ref({} as { [key: string]: number});
const filesPath = ref({} as { [key: string]: string});
const filesImageData = ref({} as { [key: string]: ImageData});
const fileDatas = computed(() => Object.keys(files.value).map((key) => ({
  priority: filesPriority.value[key],
  imageData: filesImageData.value[key],
})));

onBeforeUnmount(() => {
  uppy.close({ reason: 'unmount' });
});

uppy.on('file-added', (file: UppyFile) => {
  files.value[file.id] = file;
});

uppy.on('thumbnail:generated', (file: UppyFile) => {
  files.value[file.id] = file;
});

uppy.on('file-removed', (file: UppyFile) => {
  delete files.value[file.id];
});

uppy.on('upload-success', (file, response) => {
  const uploadedFileData = response.body.data;
  const uppyFile = file as UppyFile;
  filesImageData.value[uppyFile.id] = uploadedFileData;
  if (response?.uploadURL) filesPath.value[uppyFile.id] = response.uploadURL;
  emit('update-files', fileDatas.value);
  emit('update-file-paths', filesPath.value);
});
</script>
