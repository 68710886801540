<template>
  <div
    class="flex flex-col items-center p-5 mb-3 bg-gray-50 rounded-md md:flex-row"
  >
    <UserCircleIcon class="shrink-0 w-10 h-10 fill-lk-green md:mr-5 md:w-14 md:h-14" />
    <div class="flex flex-col w-full">
      <p class="flex flex-col items-center mb-1 md:items-start">
        <span class="font-semibold">{{ user.firstName }} {{ user.lastName }}</span>
      </p>
      <p class="flex justify-center items-center font-light md:justify-start">
        <EnvelopeIcon class="shrink-0 mr-1 w-4 h-4 " />
        <span class="truncate">{{ user.email }}</span>
      </p>
      <p class="flex justify-center items-center font-light md:justify-start">
        <PhoneIcon class="shrink-0 mr-1 w-4 h-4 " />
        <span>{{ user.contactNumber }}</span>
      </p>
      <div class="flex flex-col items-center font-light md:flex-row md:items-start">
        <lokal-tooltip
          main-label="¿Notificaciones activadas?"
          class="mr-2"
          information="Solo notificaciones por correo"
          button-classes="flex z-0 items-center space-x-1 focus:outline-none md:space-x-2"
        />
        <lokal-loading
          v-if="makerUserNotificationQuery.isLoading.value"
          :loading="makerUserNotificationQuery.isLoading.value"
          size="xs"
        />
        <lokal-switch
          v-else
          v-model="notificationActive"
          :disabled="editNotificationDisabled"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useQuery, useMutation } from 'vue-query';
import { EnvelopeIcon, PhoneIcon, UserCircleIcon } from '@heroicons/vue/24/solid';
import makerUserNotificationsApi from 'api/maker-user-notifications';
import useMessageStore from 'stores/message-store';
import LokalSwitch from './shared/lokal-switch.vue';
import LokalTooltip from './shared/lokal-tooltip.vue';

const props = withDefaults(defineProps<{
  user: User,
  editNotificationDisabled?: boolean
}>(), {
  editNotificationDisabled: false,
});

const messageStore = useMessageStore();
const makerUserNotificationQuery = useQuery(['makerUserNotification', props.user],
  () => makerUserNotificationsApi.get(props.user.id, props.user.makerId));
const makerUserNotification = computed(() =>
  makerUserNotificationQuery.data.value?.data.makerUserNotification as MakerUserNotification);
const notificationActive = ref(undefined as boolean | undefined);
const updateNotification = useMutation(
  (active: boolean) => makerUserNotificationsApi.update(props.user.id, props.user.makerId, active),
  {
    onSuccess: () => {
      messageStore.kind = 'success';
      messageStore.message = 'Notificación actualizada';
    },
    onError: () => {
      messageStore.kind = 'error';
      messageStore.message = 'No se pudo actualizar las notificaciones';
    },
  },
);

watch(notificationActive, (newValue, oldValue) => {
  if (oldValue !== undefined && newValue !== oldValue) {
    updateNotification.mutate(newValue as boolean);
  }
});

onMounted(async () => {
  await makerUserNotificationQuery.suspense();
  notificationActive.value = makerUserNotification.value?.active;
});
</script>
