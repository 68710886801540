<template>
  <div class="grid grid-cols-1 p-5 mb-10 w-full max-w-6xl md:grid-cols-12 md:py-10 md:px-5">
    <lokal-banner
      v-model="showProductInactiveOrOutOfStockBanner"
      class="col-span-1 mb-5 md:col-span-12"
      :message="inactiveOrOutOfStockMessage"
      color-key="alert"
    />
    <product-show-images :product="product" />
    <div class="flex flex-col col-span-1 mb-8 text-gray-700 md:col-span-4 md:ml-5">
      <h2 class="mb-1 text-base font-light md:mb-2 md:text-lg">
        <a :href="`/makers/${maker.slug}`">{{ product.makerName }}</a>
      </h2>
      <h3 class="mb-2 font-serif text-2xl text-lk-green md:mb-4 md:text-3xl">
        {{ product.name }}
      </h3>
      <p
        v-if="product.activeDiscounts?.length || product.recommended"
        class="flex items-center mb-2 font-light"
      >
        <span
          v-if="product.recommended"
          class="p-1 mr-2 text-sm text-white bg-lk-light-green rounded-md md:p-2 md:text-base"
        >
          Recomendado
        </span>
        <span
          v-if="product.activeDiscounts?.length"
          class="p-1 mr-2 text-sm text-white bg-lk-light-purple rounded-md md:p-2 md:text-base"
        >
          {{ product.activeDiscounts[0].totalDiscount }}% de {{ isMobile ? 'desc' : 'descuento' }}
        </span>
        <span
          v-if="product.activeDiscounts?.length"
          class="p-1 text-sm text-white bg-lk-light-purple rounded-md md:p-2 md:text-base"
        >
          {{ product.activeDiscounts[0].name }}
        </span>
      </p>
      <lokal-loading
        v-if="labelsQueryIsLoading"
        v-model="labelsQueryIsLoading"
        size="xs"
      />
      <div
        v-else
        class="flex flex-wrap items-center mb-1 w-full"
      >
        <div
          v-for="selectedLabel in labels"
          :key="`selected-product-label-${selectedLabel.id}`"
          class="flex shrink-0 items-center py-1 px-2 mr-2 text-sm text-white bg-lk-green rounded-full md:text-base"
        >
          <span class="mr-1">{{ selectedLabel.name }}</span>
        </div>
      </div>
      <div class="flex flex-col mb-2">
        <p
          class="flex text-lg"
          :class="{ 'font-semibold': activeDiscount }"
        >
          <lokal-popover-information
            v-if="wholesalePrices?.length && wholesalePrices?.length > 1"
            button-classes="flex space-x-1 mr-2"
            :information="wholesalePriceInformation"
            main-label="Precio Mayorista Neto:"
            position-classes="w-72 left-0"
          />
          <span
            v-else
            class="mr-2 mb-1"
          >
            Precio Mayorista Neto:
          </span>
          <template v-if="loadWholesalePrice">
            <span
              class="font-normal"
              :class="{'line-through mr-2': activeDiscount || showDiscountedAmount }"
            >
              {{ useFormatCurrency(product.wholesalePriceCents) }}
            </span>
            <span
              v-if="showDiscountedAmount"
              :class="{ 'blur-sm': !sessionStore.user?.id }"
            >
              {{ useFormatCurrency(productWholesalePrice) }}
            </span>
          </template>
          <lokal-popover-information
            v-else
            information="Para ver el precio mayorista, debes iniciar sesión"
          >
            <template #custom-content>
              <LockClosedIcon class="shrink-0 w-4 h-4" />
              <span class=" blur-sm">
                {{ useFormatCurrency(product.unitaryPriceCents) }}
              </span>
            </template>
          </lokal-popover-information>
        </p>
        <p class="mb-1 text-lg">
          <span class="mr-2">Precio Público c/IVA:</span>
          <span>{{ useFormatCurrency(product.unitaryPriceCents) }}</span>
        </p>
        <div
          v-if="margin"
          class="flex items-center mb-1 space-x-2 text-lg"
        >
          <lokal-tooltip
            main-label="Márgen:"
            :position-options="'left'"
            information-classes="p-2 w-72"
            button-classes="flex items-center space-x-1"
            :information="marginInformation"
          />
          <span>{{ margin }}%</span>
        </div>
        <div
          v-if="sessionStore.user?.id && product.inventory?.enable"
          class="flex mb-1 text-lg"
        >
          <lokal-tooltip
            class="mr-2"
            main-label="Unidades disponible:"
            :position-options="'left'"
            information-classes="w-40 p-1 md:p-2 md:w-64 text-xs md:text-sm"
            button-classes="flex items-center space-x-1"
            :information="'Cantidad de productos que quedan disponibles'"
          />
          <span>{{ product.inventory?.quantity }}</span>
        </div>
        <p class="mb-2 text-base font-light">
          IVA Incluido
        </p>
        <div
          v-if="variants?.length"
          class="flex flex-col mb-3 space-y-2 md:w-1/2"
        >
          <lokal-selector
            v-if="colorPresent"
            v-model="selectedColor"
            key-option="id"
            :options="uniqBy(variants.map((variant) => ({ id: variant.colorId, name: variant.colorName })), 'id')"
            option-label="name"
            options-label="Color"
            :multiple="false"
          />
          <lokal-selector
            v-if="sizePresent && filterSizes?.length"
            v-model="selectedSize"
            key-option="id"
            :options="uniqBy(filterSizes.map((variant) => ({ id: variant.sizeId, name: variant.sizeName })), 'id')"
            option-label="name"
            options-label="Talla"
            :multiple="false"
          />
          <lokal-selector
            v-if="aromaPresent"
            v-model="selectedAroma"
            key-option="id"
            :options="uniqBy(variants.map((variant) => ({ id: variant.aromaId, name: variant.aromaName })), 'id')"
            option-label="name"
            options-label="Aroma"
            :multiple="false"
          />
        </div>
        <product-shopping-button
          :product="product"
          :variant="selectedVariant"
          :variant-needed="!!product.variants?.length"
          class="mb-2 md:ml-0 md:w-auto"
          @show-register-dialog="showRegisterDialog = !showRegisterDialog"
        />
        <p class="mb-1 text-base font-light">
          Monto mínimo: {{ useFormatCurrency(maker.minimumPurchaseAmountCents) }}
        </p>
      </div>
      <div class="mb-4 w-full border-t border-lk-light-gray border-dashed" />
      <div class="flex flex-col">
        <p class="mb-1 text-base">
          Unidades mínimas: {{ product.minimumPurchaseQuantity }} producto/s
        </p>
        <p class="flex flex-col mb-1 text-base">
          Despacho en promedio de {{ maker.averageDeliveryDays }} días hábiles.
          <span class="text-sm font-light">*El tiempo de despacho puede variar según stock.</span>
        </p>
        <p
          v-if="product.shelfLife && product.shelfLife > 0"
          class="flex items-center p-2 mb-3 text-sm rounded-md border border-gray-400 md:mb-5 md:text-base"
        >
          <ClockIcon class="shrink-0 mr-2 w-4 h-4 md:mr-3 md:w-5 md:h-5" />
          <span>
            Este producto tiene una duración desde fabricación de
            {{ product.shelfLife }} {{ t(`product.shelfLifeUnit.${product.shelfLifeUnit}`) }}.
          </span>
        </p>
        <p class="">
          Descripción
        </p>
        <div
          class="mb-1 text-base font-light"
          v-html="DOMPurify.sanitize(product.description, { USE_PROFILES: { html: true }})"
        />
        <p
          v-if="product.specifications"
          class="flex flex-col mb-2 text-base"
        >
          <span>Especificaciones</span>
          <span class="font-light">{{ product.specifications }}</span>
        </p>
        <p
          v-if="product.withFreeReturns"
          class="flex items-center p-2 text-sm text-white bg-lk-light-green rounded-md md:text-base"
        >
          <inline-svg-wrapper
            name="arrow-path"
            class="shrink-0 mr-2 w-4 h-4 md:mr-3 md:w-5 md:h-5"
          />
          <span>Este producto tiene devoluciones gratis en la primera compra que hagas a esta marca.</span>
        </p>
      </div>
    </div>
    <div class="flex flex-col col-span-1 w-full text-gray-700 md:col-span-12">
      <p class="mb-6 text-lg font-medium md:text-xl">
        Otros productos de {{ maker.name }}
      </p>
      <div class="grid grid-cols-2 gap-x-5 gap-y-10 mb-10 md:grid-cols-3 md:gap-x-8 md:gap-y-14 xl:grid-cols-4">
        <product-card
          v-for="makerProduct in makerProducts"
          :key="makerProduct.id"
          :product="makerProduct"
          :shopping-button-enabled="false"
        />
      </div>
      <a
        :href="`/makers/${maker.slug}`"
        class="self-center py-2 px-8 text-base text-white bg-lk-light-gray hover:bg-lk-green rounded-md md:text-lg"
      >
        Ver Productos
      </a>
    </div>
    <lokal-user-register-dialog
      v-if="showRegisterDialog"
      v-model="showRegisterDialog"
      :product="product"
      :initial-value-sign-in="false"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, inject, ref, watch, type Ref } from 'vue';
import { uniqBy, round } from 'lodash';
import { LockClosedIcon, ClockIcon } from '@heroicons/vue/24/solid';
import DOMPurify from 'isomorphic-dompurify';
import useCartStore from 'stores/cart';
import { useI18n } from 'vue-i18n';
import useSessionStore from 'stores/user-store';
import useFormatCurrency from './use/format-currency';
import useActiveProductDiscounts from './use/active-product-discounts';
import ProductShoppingButton from './product-shopping-button.vue';
import productCard from './product-card.vue';
import LokalSelector, { type OptionRecord } from './shared/lokal-selector.vue';
import lokalBanner from './shared/lokal-banner.vue';
import productShowImages from './product-show-images.vue';
import useProductWholesalePriceMethods from './use/product-wholesale-price';
import lokalPopoverInformation from './shared/lokal-popover-information.vue';
import lokalUserRegisterDialog from './shared/lokal-user-register-dialog.vue';
import lokalTooltip from './shared/lokal-tooltip.vue';
import useLabelsQuery from './queries/labels-query';
import inlineSvgWrapper from './inline-svg-wrapper.vue';
import useBillingTools from './use/billing-tools';

const TOTAL_PORCENTAGE = 100;

const props = defineProps<{
  product: Product,
  maker: Maker,
  makerProducts: Product[],
}>();

const { billTools, convertAdditionalTax, additionalTaxInitials } = useBillingTools();

const { t } = useI18n();

const cartStore = useCartStore();
const sessionStore = useSessionStore();
const quantity = computed(() => cartStore.items[props.product.id]?.quantity || 0);
const isMobile = inject<Ref<boolean>>('isMobile');
const { productWholesalePrice, wholesalePrices } = useProductWholesalePriceMethods(props.product, quantity);
const showDiscountedAmount = computed(() => productWholesalePrice.value < props.product.wholesalePriceCents);
const loadWholesalePrice = computed(() => !!sessionStore.user?.id);
const showRegisterDialog = ref(false);

const showProductInactiveOrOutOfStockBanner = computed(() => props.product.status !== 'active');
const inactiveOrOutOfStockMessage = computed(() => {
  if (props.product.status === 'inactive') {
    return 'Producto inactivo';
  } else if (props.product.status === 'out_of_stock') {
    return 'Producto sin stock';
  }

  return '';
});
const currentAdditionalTax = computed(() => convertAdditionalTax(props.product.additionalTax));
const additionalTaxValue = computed(() => round(productWholesalePrice.value * currentAdditionalTax.value));
const netWholesalePriceWithAdditionalTax = computed(() => productWholesalePrice.value + additionalTaxValue.value);
const margin = computed(() => {
  if (props.product.unitaryPriceCents === 0) return 0;

  const netUnitaryPrice = round(props.product.unitaryPriceCents / billTools.value.ivaValue);
  const diff = netUnitaryPrice - netWholesalePriceWithAdditionalTax.value;

  return round((diff / netUnitaryPrice) * TOTAL_PORCENTAGE);
});

const selectedVariant = ref({} as Variant);
const variants = computed(() => props.product.variants);
const selectedSize = ref({} as OptionRecord);
const selectedAroma = ref({} as OptionRecord);
const selectedColor = ref({} as OptionRecord);
const filterSizes = computed(() => {
  if (selectedColor.value?.id) {
    return variants.value?.filter((variant) => variant.colorId === selectedColor.value.id);
  }

  return variants.value;
});
function presentInVariants(value: keyof Variant) {
  const found = variants.value?.filter((variant: Variant) => variant[value] !== null);

  return found && found.length > 0;
}
const sizePresent = computed(() => presentInVariants('sizeId'));
const aromaPresent = computed(() => presentInVariants('aromaId'));
const colorPresent = computed(() => presentInVariants('colorId'));

watch(selectedColor, (newColorRecord: OptionRecord) => {
  if (variants.value?.length) {
    const filterVariantss = variants.value.filter((variant) => variant.colorId === newColorRecord.id);
    selectedVariant.value = filterVariantss[0];
    selectedSize.value = { id: selectedVariant.value.sizeId, name: selectedVariant.value.sizeName };
  }
});

watch(selectedAroma, (newAromaRecord: OptionRecord) => {
  if (variants.value?.length) {
    const filterVariantss = variants.value.filter((variant) => variant.aromaId === newAromaRecord.id);
    selectedVariant.value = filterVariantss[0];
  }
});

const labelsQueryParams = computed(() => `q[products_id_eq]=${props.product.id}`);
const { labels, labelsQuery } = useLabelsQuery(labelsQueryParams, computed(() => true));
const labelsQueryIsLoading = computed(() => labelsQuery.isLoading.value);

const { activeDiscount } = useActiveProductDiscounts(props.product);
const wholesalePriceInformation = computed(() => `
  Este producto tiene descuento según la cantidad que se compre. A continuación se muestran
  los tramos: <br>
  ${
  wholesalePrices.value?.map((price) =>
    `- ${price.minimumQuantity} o más: ${useFormatCurrency(price.amountCents)}`).join('<br>')
}
`);

const marginInformation = computed(() => {
  if (currentAdditionalTax.value > 0) {
    return `
    (Precio Público - (Precio Mayorista + ${additionalTaxInitials(props.product.additionalTax)})) / Precio Público <br>
    Ambos precios sin IVA.
  `;
  }

  return `
    (Precio Público - Precio Mayorista) / Precio Público <br>
    Ambos precios sin IVA.
  `;
});
</script>
