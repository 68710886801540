<template>
  <Dialog
    class="relative z-50"
    :open="modelValue"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2 text-gray-700">
      <DialogPanel class="flex overflow-y-auto flex-col p-3 w-full max-w-md max-h-[400px] text-sm bg-gray-50 rounded md:p-5 md:max-h-[550px] md:text-base">
        <button
          class="ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-5 h-5 fill-gray-700" />
        </button>
        <DialogTitle class="mb-3 w-full font-serif text-2xl text-center md:text-3xl">
          Solicitar Retiro
        </DialogTitle>
        <DialogDescription class="mb-3 text-sm font-light text-gray-700 md:text-base">
          <span class="font-normal">Para BLUEEXPRESS debes tener al menos 4 bultos para poder retirar.</span>
        </DialogDescription>
        <lokal-loading
          v-if="isLoading"
          class="self-center"
          :loading="isLoading"
        />
        <div
          v-else-if="subOrders && subOrders?.length === 0"
          class="flex flex-col text-gray-700"
        >
          <p class="mb-3 text-sm md:mb-5 md:text-base">
            Para generar un retiro debes tener al menos 1 pedido en estado "confirmado" (con despacho pendiente).
            Puedes revisar el estado de tus pedidos en la sección de pedidos.
          </p>
          <a
            :href="`/makers/${maker.slug}/admin?tab=subOrders`"
            class="p-1.5 text-sm text-center text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green cursor-pointer  md:p-2 md:text-base"
          >
            Revisar pedidos
          </a>
          <button
            class="text-xs hover:underline md:text-sm"
            @click="emit('update:modelValue', false)"
          >
            Cerrar
          </button>
        </div>
        <form
          v-else
          class="flex flex-col text-gray-700"
          @submit="onSubmit"
        >
          <p>Selecciona la dirección de retiro:</p>
          <div class="flex flex-col mb-2">
            <lokal-generic-combobox
              v-model="selectedWarehouse"
              :options="warehouses"
              :get-option-key="(option: Warehouse) => option.id"
              :get-option-label="(option: Warehouse) => option.address"
              options-label="Selecciona una direción de retiro"
              :multiple="false"
            />
            <Field
              v-model="selectedWarehouseId"
              name="warehouseId"
              label="dirección de retiro"
              hidden
              :rules="{ required: true }"
            />
            <ErrorMessage
              name="warehouseId"
              class="mt-1 text-xs text-red-500 md:text-sm"
            />
          </div>
          <p>Selecciona un carrier:</p>
          <div class="flex flex-col mb-2">
            <lokal-generic-combobox
              v-model="selectedCarrier"
              :get-option-key="(option: Carrier) => option.id"
              :get-option-label="(option: Carrier) => option.name"
              :options="carriers"
              options-label="Selecciona un carrier"
              :multiple="false"
            />
            <Field
              v-model="selectedCarrierId"
              name="carrierRecord"
              label="carrier"
              hidden
              :rules="{ required: true }"
            />
            <ErrorMessage
              name="carrierRecord"
              class="mt-1 text-xs text-red-500 md:text-sm"
            />
          </div>
          <p>Fecha de retiro:</p>
          <div class="flex flex-col mb-2">
            <lokal-datepicker
              v-if="selectedCarrier.code"
              v-model="form.pickupDate"
              :min-date="minDate"
              :auto-apply="true"
              :range="false"
              :disabled-dates="disabledDates"
            />
            <input
              v-else
              v-model="form.pickupDate"
              disabled
              type="date"
              class="p-2 mb-3 font-light rounded-md border border-lk-light-gray focus:outline-none"
            >
            <Field
              v-model="form.pickupDate"
              name="pickupDate"
              label="fecha de retiro"
              hidden
              :rules="{ required: true }"
            />
            <ErrorMessage
              name="pickupDate"
              class="mt-1 text-xs text-red-500 md:text-sm"
            />
          </div>
          <template v-if="selectedCarrier.code === 'BLX'">
            <p>Rango horario:</p>
            <div class="flex flex-col mb-2">
              <lokal-datepicker
                v-model="form.rangeTime"
                :time-picker="true"
                :min-time="{ hours: 9, minutes: 0 }"
                :max-time="{ hours: 18, minutes: 0 }"
                :auto-apply="true"
                :start-time="[{ hours: 9, minutes: 0 }, { hours: 11, minutes: 0 }]"
                :range="true"
                :minutes-increment="30"
              />
              <Field
                v-model="form.rangeTime"
                name="rangeTime"
                label="rango horario"
                hidden
                :rules="{ validateRangeTime: TWO_HOURS_IN_MS }"
              />
              <ErrorMessage
                name="rangeTime"
                class="mt-1 text-xs text-red-500 md:text-sm"
              />
            </div>
          </template>
          <p>Observaciones</p>
          <Field
            v-model="form.information"
            as="textarea"
            class="p-2 mb-3 h-14 font-light rounded-md border border-lk-light-gray focus:outline-none"
            :rules="{ required: false }"
            name="text"
          />
          <lokal-loading
            v-if="createPickupMutation.isLoading.value"
            :loading="createPickupMutation.isLoading.value"
            class="self-center"
          />
          <div
            v-else
            class="flex flex-col items-center w-full"
          >
            <button
              class="p-2 mb-2 w-full text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
              type="submit"
            >
              Generar retiro
            </button>
            <button
              class="text-xs hover:underline md:text-sm"
              @click="emit('update:modelValue', false)"
            >
              Cancelar
            </button>
          </div>
        </form>
      </DialogPanel>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { ref, computed, watch } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { useMutation, useQuery } from 'vue-query';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import pickupsApi from 'api/pickups';
import warehousesApi from 'api/warehouses';
import useMessageStore from 'stores/message-store';
import LokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import useCarriersQuery from './queries/carriers-query';
import lokalDatepicker from './shared/lokal-datepicker.vue';
import useSubOrdersQuery from './queries/sub-orders-query';

// eslint-disable-next-line no-magic-numbers
const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;
const SATURDAY = 6;
const DISABLED_DAYS = [0, SATURDAY];
const confirmedStatus = 2;
const TWO_DIGITS = 2;

interface EnviamePickupsFormProps {
  maker: Maker
  modelValue: boolean
}
const props = withDefaults(defineProps<EnviamePickupsFormProps>(), {
  modelValue: false,
});

const messageStore = useMessageStore();

const carriersQueryParams = computed(() =>
  `q[maker_carriers_maker_id_eq]=${props.maker.id}&q[maker_carriers_active_eq]=true`);
const { carriers, carriersQuery } = useCarriersQuery(carriersQueryParams);
const warehousesQuery = useQuery(['warehouses', props.maker.id], () => warehousesApi.index(props.maker.id));
const warehouses = computed(() => warehousesQuery.data.value?.data.warehouses as Warehouse[]);

const selectedWarehouse = ref({} as Warehouse);
const selectedWarehouseId = computed(() => selectedWarehouse.value?.id);
const selectedCarrier = ref({} as Carrier);
const selectedCarrierId = computed(() => selectedCarrier.value.id);

const subOrdersQueryParams = computed(() => `q[status_eq]=${confirmedStatus}`);
const { subOrders, isSubOrderQueryLoading } = useSubOrdersQuery(
  computed(() => props.maker.id), ref(1), subOrdersQueryParams,
);

const isLoading = computed(() => carriersQuery.isLoading.value ||
  warehousesQuery.isLoading.value || isSubOrderQueryLoading.value);

function minDateForCarriers() {
  const maximumHours = 10;
  const maximumMinutes = 30;
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  if (hours > maximumHours || (hours === maximumHours && minutes > maximumMinutes)) {
    now.setDate(now.getDate() + 1);
  }

  return now;
}

const minDate = computed(() => {
  let internalMinDate = new Date();

  if (selectedCarrier.value?.code === 'BLX') {
    internalMinDate.setDate(internalMinDate.getDate() + 1);
  } else {
    internalMinDate = minDateForCarriers();
  }

  // Ensure the date is not a weekend
  if (internalMinDate.getDay() === 0 || internalMinDate.getDay() === SATURDAY) {
    const adjustment = internalMinDate.getDay() === 0 ? 1 : TWO_DIGITS;
    internalMinDate.setDate(internalMinDate.getDate() + adjustment);
  }

  return internalMinDate;
});

const form = ref({
  pickupDate: minDate.value,
  numberOfPackages: 0,
  totalWeight: 0,
  carrierCodes: '',
  information: '',
  rangeTime: [] as Record<string, number>[],
});

watch(minDate, () => {
  form.value.pickupDate = minDate.value;
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void, (e: 'reload-pickups'): void}>();

function padTime(value: number) {
  return String(value).padStart(TWO_DIGITS, '0');
}

function getFormattedTime(rangeTime?: Record<string, number>) {
  if (!rangeTime) return '';

  return `${padTime(rangeTime?.hours)}:${padTime(rangeTime?.minutes)}`;
}

const createPickUpData = computed(() => ({
  makerId: props.maker.id,
  carrierId: selectedCarrierId.value,
  warehouseId: selectedWarehouse.value.id,
  packageQty: 4,
  weight: 31,
  pickupDate: form.value.pickupDate.toISOString().split('T')[0], // YYYY-MM-DD
  rangeTime: 'PM',
  initialTime: `${getFormattedTime(form.value.rangeTime?.[0])}`,
  endTime: `${getFormattedTime(form.value.rangeTime?.[1])}`,
  size: 'l',
  information: form.value.information,
}));
const createPickupMutation = useMutation(
  () => pickupsApi.create(createPickUpData.value),
  {
    onSuccess: () => {
      messageStore.kind = 'success';
      messageStore.message = 'Retiro creado con éxito';
      emit('reload-pickups');
      emit('update:modelValue', false);
    },
  },
);

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  createPickupMutation.mutate();
});

function disabledDates(date: Date) {
  const day = date.getDay();

  return DISABLED_DAYS.includes(day);
}
</script>
