<template>
  <tr :class="{ 'border-t border-l border-r border-gray-500': originalQuantity !== quantity || newProduct }">
    <td class="shrink-0 py-1 px-2">
      <img
        :src="mainImageUrl"
        alt="product image"
        class="w-10 h-10 md:w-12 md:h-12"
      >
    </td>
    <td class="shrink-0 py-1 px-2 text-center">
      <p class="flex flex-col text-xs font-light md:text-sm">
        <span class="text-sm font-normal md:text-base">{{ productName }}</span>
        <span
          v-if="newProduct"
        >
          Nuevo producto
        </span>
        <span
          v-else-if="quantity !== originalQuantity && quantity >= 0"
        >
          Cambió de {{ originalQuantity }} a {{ quantity }}
        </span>
      </p>
    </td>
    <td class="shrink-0 py-1 px-2 text-center">
      <Field
        v-model.number="quantity"
        type="number"
        class="p-1.5 w-14 text-xs text-center rounded-md border border-lk-light-gray focus:border-lk-green focus:outline-none md:p-2 md:w-14 md:text-sm"
        :name="inputName"
        :label="`cantidad de ${productName}`"
        :rules="{ required: true, min_value: minimumQuantity }"
      />
    </td>
    <td class="shrink-0 py-1 px-2 w-14 text-center md:w-20">
      <lokal-generic-combobox
        v-if="quantity === 0"
        v-model="replacemenTimeSelected"
        class="shrink-0 w-32 md:w-36"
        text-size="text-xs md:text-sm"
        :get-option-key="(option: Record<string, number>) => option.key"
        :get-option-label="(option: Record<string, number>) => option.label"
        compare-by="key"
        :options="replacementTimeOptions"
        :options-label="`Reposición`"
        :multiple="false"
        :local-search="false"
      />
      <div
        v-else
        class="shrink-0 w-32 md:w-36"
      >
        -
      </div>
    </td>
  </tr>
  <tr :class="{ 'border-b border-l border-r border-gray-500': originalQuantity !== quantity || newProduct }">
    <td
      colspan="4"
      class="py-1 px-2 text-xs font-light text-left md:text-sm"
    >
      <ErrorMessage
        v-slot="{ message }"
        as="td"
        class="p-2 text-red-500"
        colspan="4"
        :name="inputName"
      >
        {{ message }}
      </ErrorMessage>
      <ErrorMessage
        v-slot="{ message }"
        as="td"
        :name="replacementDaysInputName"
        class="p-2 text-red-500"
        colspan="4"
      >
        {{ message }}
      </ErrorMessage>
    </td>
  </tr>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';

interface SubOrderAdvancePaymentDialogProps {
  modelValue: Record<string, Record<string, number>>
  maker: Maker
  subOrder: SubOrder
  uniqueId: string
  mainImageUrl: string
  productId: number
  productName: string
  originalQuantity: number
  newProduct?: boolean
}
const props = withDefaults(defineProps<SubOrderAdvancePaymentDialogProps>(), {
  newProduct: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: Record<string, Record<string, number>>): void,
}>();

const originalQuantity = ref(props.newProduct ? 0 : props.originalQuantity);
const quantity = ref(props.originalQuantity);
const inputName = ref(`quantity-${props.uniqueId}`);
const replacementDaysInputName = ref(`replacement-days-${props.uniqueId}`);

const replacementTimeOptions = ref([
  { key: 1, label: 'Menos de 3 días' },
  { key: 2, label: 'Entre 3 y 7 días' },
  { key: 3, label: 'Entre 7 y 14 días' },
  { key: 4, label: 'Sin fecha estimada' },
]);
const replacemenTimeSelected = ref({ key: 0, label: '' });

const minimumQuantity = computed(() => (props.newProduct ? 1 : 0));

function setNewQuantityData() {
  const data = {
    productId: props.productId,
    subOrderId: props.subOrder.id,
    originalQuantity: originalQuantity.value,
    newQuantity: quantity.value,
    replacementTime: replacemenTimeSelected.value.label,
  };

  return { ...props.modelValue, [props.productId]: data };
}

watch(quantity, () => {
  if (quantity.value !== 0) {
    replacemenTimeSelected.value = { key: 0, label: '' };
  }
  if (quantity.value === originalQuantity.value) {
    const newData = { ...props.modelValue };
    delete newData[props.productId];
    emit('update:modelValue', newData);
  } else {
    emit('update:modelValue', setNewQuantityData());
  }
});

watch(replacemenTimeSelected, () => {
  emit('update:modelValue', setNewQuantityData());
});

onMounted(() => {
  if (props.newProduct) {
    emit('update:modelValue', setNewQuantityData());
  }
});

onUnmounted(() => {
  if (props.newProduct) {
    const newData = { ...props.modelValue };
    delete newData[props.productId];
    emit('update:modelValue', newData);
  }
});
</script>
