import StarterKit from '@tiptap/starter-kit';

export default StarterKit.configure({
  blockquote: {
    HTMLAttributes: {
      class: 'border-solid border-gray-300 ml-1 pl-2',
      style: 'border-left-width: 0.3em;',
    },
  },
  bulletList: {
    HTMLAttributes: {
      class: 'list-disc ml-4',
      stlye: '',
    },
  },
  codeBlock: {
    HTMLAttributes: {
      class: 'text-sm text-purple-500 font-normal p-1 rounded-sm',
    },
  },
  heading: false,
  listItem: {
    HTMLAttributes: {
      style: 'display: list-item',
    },
  },
  orderedList: {
    HTMLAttributes: {
      class: 'list-decimal ml-4',
    },
  },
});
