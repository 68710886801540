<template>
  <div class="flex flex-col items-center pb-6 w-full bg-gray-50 md:pb-10">
    <OrderShowStatus
      :order="order"
      :sub-orders="subOrders"
      class="mb-2"
    />
    <order-show-initial-message
      v-if="order.status === 'pending'"
      class="overflow-y-auto p-4 mb-5 max-w-3xl max-h-[200px] bg-white rounded-md md:mb-7"
      :order="order"
      :total-order-amount="totalWholesalePrice"
      :total-amount-with-taxes="totalWholesalePriceWithTaxes"
      :show-icon="false"
    />
    <div class="flex flex-col py-3 px-5 mb-10 w-full max-w-3xl text-gray-700 bg-white rounded-md md:p-10">
      <div class="flex justify-between items-center mb-5">
        <a
          class="flex items-center text-sm hover:underline md:text-base"
          href="/shops/account?tab=orders"
        >
          <ChevronLeftIcon class="mr-2 w-4 h-4 md:w-5 md:h-5" />
          Volver
        </a>
      </div>
      <div class="flex items-center mb-2">
        <h1 class="mr-2 text-2xl md:mr-3 md:text-3xl">
          <span>Orden #{{ order.id }}</span>
        </h1>
        <span
          class="py-1 px-2 mr-2 text-xs text-center text-white rounded-full md:mr-3 md:text-base"
          :class="[paymentStatusClasses]"
        >
          {{ t(`order.paymentStatus.${camelCase(order.paymentStatus)}`) }}
        </span>
      </div>
      <p
        v-if="order.address"
        class="flex flex-col mb-5 text-sm md:text-base"
      >
        <span>Dirección de envío: {{ order.address.address }}</span>
        <a
          v-if="order.orderTrackingUrl"
          :href="order.orderTrackingUrl"
          class="text-sm underline"
        >
          Link de seguimiento
        </a>
      </p>
      <div class="flex flex-col-reverse mb-8 w-full md:flex-row">
        <div class="flex flex-col justify-center text-sm md:text-base">
          <button
            class="p-1 mb-1 hover:text-white hover:bg-gray-700 rounded-md border border-gray-700 md:p-1.5"
            @click="showPaymentInformationDialog = true"
          >
            Datos de transferencia
          </button>
          <a
            :href="order.invoiceUrl"
            target="_blank"
            noopener
            class="p-1 mb-1 w-full text-center rounded-md border border-gray-700 md:p-1.5"
            :class="{
              'hover:text-white hover:bg-gray-700 cursor-pointer': order.invoiceUrl,
              'cursor-default': !order.invoiceUrl
            }"
          >
            {{ order.invoiceUrl ? 'Descargar factura' : 'Factura no disponible' }}
          </a>
          <form
            :action="`/orders/${order.id}/download_excel`"
            method="post"
            class="shrink-0 w-full"
          >
            <csrfInputVue />
            <button
              rel="nofollow"
              class="p-1 mb-1 w-full hover:text-white hover:bg-gray-700 rounded-md border border-gray-700 md:p-1.5 md:mb-0"
            >
              Descargar excel
            </button>
          </form>
        </div>
        <div class="flex flex-col mb-2 md:mb-0 md:ml-auto">
          <p class="mb-2 text-lg border-b-2 border-gray-700 md:ml-auto">
            Resumen
          </p>
          <p
            v-if="isDiscountActive"
            class="flex mb-1 space-x-2 text-sm md:ml-auto md:text-base"
          >
            <span>Desct Tolal:</span>
            <span>{{ useFormatCurrency(totalDiscounts) }}</span>
          </p>
          <p
            class="flex text-sm md:ml-auto md:text-base"
            :class="{ 'md:justify-between': !isDiscountActive }"
          >
            <span class="mr-2">Sub Total:</span>
            <span
              v-if="isDiscountActive"
              class="mr-2 line-through md:ml-auto"
            >
              {{ useFormatCurrency(order.totalWholesaleAmountCents) }}
            </span>
            <span>
              {{ useFormatCurrency(order.totalWholesaleAmountCents - totalDiscounts) }}
            </span>
          </p>
          <p class="flex space-x-2 text-sm md:ml-auto md:text-base">
            <span>Costos de Despacho:</span>
            <span v-if="anySubOrderHasShippingCostConfirmAfter">
              Pendiente
            </span>
            <span v-else>
              {{ useFormatCurrency(order.shippingCostAmountCents) }}
            </span>
          </p>
          <p class="flex space-x-2 text-sm md:ml-auto md:text-base">
            <span>Total neto {{ anySubOrderHasShippingCostConfirmAfter ? 'sin despacho' : '' }}:</span>
            <span>
              {{ useFormatCurrency(totalWholesalePrice) }}
            </span>
          </p>
          <p class="flex space-x-2 text-sm md:ml-auto md:text-base">
            <span>IVA:</span>
            <span>
              {{ useFormatCurrency(totalWholesalePriceIvaAmount) }}
            </span>
          </p>
          <p
            v-for="value, key in order.groupedAdditionalTaxAmounts"
            :key="`additional-tax-${key}`"
            class="flex space-x-2 text-sm md:ml-auto md:text-base"
          >
            <span>{{ additionalTaxInitials(key as string) }}:</span>
            <span>
              {{ useFormatCurrency(value) }}
            </span>
          </p>
          <p class="flex space-x-2 text-sm font-semibold md:ml-auto md:text-base">
            <span>Total bruto {{ anySubOrderHasShippingCostConfirmAfter ? 'sin despacho' : '' }}:</span>
            <span>
              {{ useFormatCurrency(totalWholesalePriceWithTaxes) }}
            </span>
          </p>
        </div>
      </div>
      <template
        v-for="subOrder, index in sortedSubOrders"
        :key="subOrder.id"
      >
        <order-show-sub-order
          :sub-order="subOrder"
          :sub-order-products="groupedSubOrderProducts[subOrder.id]"
          :is-first="index === 0"
          @reload:sub-orders="reloadWindow"
        />
      </template>
      <lokal-loading
        v-if="orderPaymentsIsLoading"
        :loading="orderPaymentsIsLoading"
      />
      <div
        v-else
        class="flex flex-col"
      >
        <p
          v-if="orderPayments && orderPayments.length > 0"
          class="mb-3 text-lg md:text-xl"
        >
          Pagos asociados a esta orden
        </p>
        <div
          v-for="orderPayment in orderPayments"
          :key="orderPayment.id"
          class="flex items-center p-3 bg-gray-50 rounded-md"
        >
          <BanknotesIcon class="shrink-0 mr-2 w-6 h-6 md:mr-5 md:w-7 md:h-7" />
          <div class="flex flex-col">
            <span class="text-base md:text-lg">
              Pago de orden
              <span class="text-lk-green">#{{ orderPayment.id }}</span>
            </span>
            <span class="text-sm font-light md:text-base">
              Monto pagado: {{ useFormatCurrency(orderPayment.amountCents) }}
            </span>
            <span />
          </div>
          <div class="self-start ml-auto text-sm md:text-base">
            {{ timeToDate(orderPayment.createdAt) }}
          </div>
        </div>
      </div>
      <lokal-payment-information-dialog
        v-if="showPaymentInformationDialog"
        v-model="showPaymentInformationDialog"
        :order="order"
      />
      <order-show-initial-message-dialog
        v-if="showNewOrderMessage"
        v-model="showNewOrderMessage"
        :order="order"
        :total-amount-with-taxes="totalWholesalePriceWithTaxes"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ChevronLeftIcon, BanknotesIcon } from '@heroicons/vue/24/outline';
import { computed, ref } from 'vue';
import { groupBy, round, camelCase, values, sum, orderBy } from 'lodash';
import { useQuery } from 'vue-query';
import { useI18n } from 'vue-i18n';
import orderPaymentsApi from 'api/order-payments';
import useDateTools from './use/date-tools';
import useFormatCurrency from './use/format-currency';
import csrfInputVue from './shared/csrf-input.vue';
import OrderShowSubOrder from './order-show-sub-order.vue';
import LokalPaymentInformationDialog from './shared/lokal-payment-information-dialog.vue';
import OrderShowStatus from './order-show-status.vue';
import OrderShowInitialMessageDialog from './order-show-initial-message-dialog.vue';
import OrderShowInitialMessage from './order-show-initial-message.vue';
import useBillingTools from './use/billing-tools';

const props = withDefaults(defineProps<{
  order: Order,
  subOrderProducts: SubOrderProduct[],
  subOrders: SubOrder[],
  newOrder?: boolean
}>(), {
  newOrder: false,
});

const { t } = useI18n();
const { timeToDate } = useDateTools();

const showNewOrderMessage = ref(props.newOrder);
const showPaymentInformationDialog = ref(false);

const { billTools, additionalTaxInitials } = useBillingTools();

const sortedSubOrders = computed(() =>
  orderBy(props.subOrders, (subOrder) => subOrder.modificationsStatus === 'in_progress', 'desc'));

const isDiscountActive = computed(() =>
  props.order.totalDiscountAmount > 0 || props.order.totalVolumeDiscountAmountCents > 0);

const groupedSubOrderProducts = computed(() =>
  (groupBy(props.subOrderProducts, (subOrderProduct) => subOrderProduct.subOrderId)));

const totalDiscounts = computed(() =>
  props.order.totalDiscountAmount + props.order.totalVolumeDiscountAmountCents + props.order.totalDiscountCodeAmount);

const anySubOrderHasShippingCostConfirmAfter = computed(() =>
  props.subOrders.some((subOrder) => subOrder.shippingQuotation === 'confirm_after'));

const totalWholesalePrice = computed(() => {
  let base = props.order.totalWholesaleAmountCents - totalDiscounts.value;
  if (!anySubOrderHasShippingCostConfirmAfter.value) base += props.order.shippingCostAmountCents;

  return base;
});

const totalWholesalePriceIvaAmount = computed(() => round(totalWholesalePrice.value * billTools.value.iva));

const totalWholesalePriceWithTaxes = computed(() => {
  const additionalTaxesAmount = sum(values(props.order.groupedAdditionalTaxAmounts));

  return totalWholesalePrice.value + totalWholesalePriceIvaAmount.value + additionalTaxesAmount;
});

const paymentStatusClasses = computed(() => {
  if (props.order.paymentStatus === 'paid') return 'bg-lk-green';
  else if (props.order.paymentStatus === 'partially_paid') return 'bg-yellow-500';

  return 'bg-lk-light-purple';
});

const orderPaymentsQuery = useQuery(
  ['orderPayments', props.order.id],
  () => orderPaymentsApi.index(props.order.shopId, 1, `q[order_id_eq]=${props.order.id}`),
  { keepPreviousData: true },
);
const orderPaymentsIsLoading = computed(() => orderPaymentsQuery.isLoading.value);
const orderPayments = computed(() => orderPaymentsQuery.data.value?.data.orderPayments as OrderPayment[]);

function reloadWindow() {
  window.location.reload();
}
</script>
