<template>
  <div class="flex flex-col w-full">
    <div
      class="grid grid-cols-5 items-center p-2 mb-2 text-xs text-center text-white bg-lk-green rounded-md md:p-3 lg:text-base"
    >
      <p>
        Imagen referencial
      </p>
      <p class="flex flex-col col-span-2">
        <span>Nombre producto</span>
        <span class="text-xs lg:text-sm">SKU</span>
      </p>
      <p class="flex flex-col">
        <span>Precio público (c/IVA) /</span>
        <span>mayorista neto</span>
      </p>
      <p class="">
        Unidades Vendidas
      </p>
    </div>
    <lokal-loading
      v-if="quantityGroupByProductIsLoading"
      :loading="quantityGroupByProductIsLoading"
    />
    <maker-metrics-product-list-card
      v-for="product in products"
      v-else
      :key="product.id"
      :product="product"
    />
    <pagination-component
      v-if="!quantityGroupByProductIsLoading && products?.length"
      :total-pages="totalPages"
      :current-page="currentPage"
      @set-new-page="(newPage: number) => currentPage = newPage"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useQuery } from 'vue-query';
import subOrderProductsApi from 'api/sub-order-products';
import useCustomParseInt from './use/custom-parse-int';
import PaginationComponent from './shared/pagination-component.vue';
import MakerMetricsProductListCard from './maker-metrics-product-list-card.vue';

const props = defineProps<{
  maker: Maker
  initialDate: string
  endDate: string
}>();

const currentPage = ref(1);
const queryParams = computed(() => {
  const params = `q[product_maker_id_eq]=${props.maker.id}&q[sub_order_pending_at_gteq]=${props.initialDate}`;

  return `${params}&q[sub_order_pending_at_lteq]=${props.endDate}&`;
});
const quantityGroupByProductQuery = useQuery(
  ['subOrderProducts', currentPage, queryParams],
  () => subOrderProductsApi.quantityGroupByProduct(currentPage.value, queryParams.value),
  { keepPreviousData: true },
);
const quantityGroupByProductIsLoading = computed(() => quantityGroupByProductQuery.isLoading.value ||
  quantityGroupByProductQuery.isPreviousData.value);
const products = computed(() => quantityGroupByProductQuery.data.value?.data.products as Product[]);

const totalResources = computed(() =>
  useCustomParseInt(quantityGroupByProductQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() =>
  useCustomParseInt(quantityGroupByProductQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));
</script>

