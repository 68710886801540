<template>
  <div
    v-if="!signUpStore.currentPathIncludesShopsSignUp"
    ref="headerRef"
    class="flex z-40 flex-col"
  >
    <the-header-top-messages />
    <div
      class="flex flex-col justify-between items-center px-2 pt-4 w-full text-gray-700 bg-white shadow-lg sm:justify-start md:px-7 md:pt-6 xl:pl-16"
      :class="{ 'pb-4': currentPathIncludesOrdersCheckout || !sessionStore.user?.id || !isShopUser || isSuperAdmin,
                'pb-2': !currentPathIncludesOrdersCheckout && isShopUser }"
    >
      <div
        v-if="!signUpStore.currentPathIncludesShopsSignUp"
        class="flex justify-between items-center w-full sm:justify-start"
      >
        <LokalUserRegisterDialog
          v-model="menuStore.showUserRegisterDialog"
          :initial-value-sign-in="menuStore.initialValueSignIn"
        />
        <button
          v-if="isMobile && !menuStore.showMenu"
          class="justify-self-start"
          @click="manageShowMenu"
        >
          <Bars3Icon class="w-7 h-7 fill-gray-700" />
        </button>
        <button
          v-if="isMobile && menuStore.showMenu"
          class="justify-self-start"
          @click="menuStore.showMenu = !menuStore.showMenu"
        >
          <XMarkIcon class="w-7 h-7 fill-gray-700" />
        </button>
        <a
          href="/"
          class="shrink-0 cursor-pointer"
        >
          <img
            src="assets/images/green-k-without-bg.png"
            class="shrink-0 mr-0 w-auto h-16 sm:mr-8 md:mr-16 md:h-[84px]"
          >
        </a>
        <div
          v-if="!isMobile"
          class="flex flex-col items-start"
        >
          <div
            v-if="!isMobile"
            class="flex items-center sm:space-x-4 sm:text-sm md:space-x-6 md:text-base lg:space-x-6 xl:space-x-9 xl:text-lg"
          >
            <a href="/makers">Marcas</a>
            <a href="/categories">Categorías</a>
            <a href="/products">Productos</a>
            <a href="/about_us">Sobre Nosotros</a>
          </div>
        </div>
        <div class="flex items-center space-x-3 sm:ml-auto md:space-x-5 lg:space-x-9">
          <template v-if="!isMobile">
            <a
              v-if="user?.makerId"
              href="/makers/account?tab=refer"
              class="self-center sm:text-sm md:text-base"
            >
              Invita a tiendas
            </a>
            <template v-if="user?.shopId">
              <lokal-loading
                v-if="isBalanceAccountLoading"
                :loading="isBalanceAccountLoading"
                size="xs"
              />
              <div
                v-else-if="balanceAccount.maximumCreditsApprovedDate"
                class="text-sm md:text-base"
              >
                Créditos: {{ useFormatCurrency(balanceAccount?.creditsLeft) }}
              </div>
            </template>
          </template>
          <button
            v-if="sessionStore.user?.id && !menuStore.showCartSidebar"
            class="self-center"
            @click="manageShowCartSidebar"
          >
            <div
              v-if="cartStore.getItemsLength"
              class="flex absolute justify-center items-center p-2 -mt-1 -ml-1 w-2 h-2 text-xs text-white bg-lk-light-purple rounded-full md:-mt-2 md:-ml-2 md:w-5 md:h-5"
            >
              {{ cartStore.getItemsLength }}
            </div>
            <ShoppingBagIcon class="w-7 h-7 fill-gray-700 md:w-8 md:h-8" />
          </button>
          <button
            v-if="sessionStore.user?.id && menuStore.showCartSidebar"
            class="justify-self-start"
            @click="menuStore.showCartSidebar = !menuStore.showCartSidebar"
          >
            <XMarkIcon class="w-7 h-7 fill-gray-700" />
          </button>
          <button
            v-if="(!isMobile && sessionStore.user?.id && !menuStore.showMenu) || (isMobile && !sessionStore.user?.id)"
            class="justify-self-end ml-auto"
            @click="manageShowMenu"
          >
            <UserCircleIcon class="w-7 h-7 text-gray-700 md:w-8 md:h-8" />
          </button>
          <button
            v-if="!isMobile && sessionStore.user?.id && menuStore.showMenu"
            class="justify-self-start"
            @click="menuStore.showMenu = !menuStore.showMenu"
          >
            <XMarkIcon class="w-7 h-7 fill-gray-700" />
          </button>
          <div
            v-else-if="!isMobile && !sessionStore.user?.id"
            class="flex ml-auto space-x-3"
          >
            <button
              class="p-2 text-sm text-center text-lk-green rounded-md border border-lk-green lg:py-2 lg:px-4 lg:text-base"
              @click="menuStore.openUserRegisterDialog(true)"
            >
              Iniciar sesión
            </button>
            <button
              class="p-2 text-sm text-center text-white bg-lk-green rounded-md lg:py-2 lg:px-4 lg:text-base"
              @click="menuStore.openUserRegisterDialog(false)"
            >
              Crear Cuenta
            </button>
          </div>
        </div>
      </div>
      <template v-if="!isSuperAdmin">
        <lokal-loading
          v-if="isLoadingShopOrAddress"
          :loading="isLoadingShopOrAddress"
          size="xs"
          class="self-start"
        />
        <the-header-shipping-address-selector
          v-else-if="!currentPathIncludesOrdersCheckout && isShopUser"
          v-model:addresses="addresses"
          :shop="shop"
          @update:shop-and-addresses="shopAndAddressesUpdate"
        />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { inject, ref, onMounted, computed, watch } from 'vue';
import { ShoppingBagIcon, Bars3Icon, UserCircleIcon } from '@heroicons/vue/24/solid';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useQuery } from 'vue-query';
import useSessionStore from 'stores/user-store';
import useCartStore from 'stores/cart';
import useSignUpStore from 'stores/sign-up-store';
import useGeneralMenuStore from 'stores/general-menu-store';
import usePotentialMakerContactStore from 'stores/potential-maker-contact-store';
import cartProductsApi from 'api/cart-products';
import productsApi from 'api/products';
import LokalUserRegisterDialog from './shared/lokal-user-register-dialog.vue';
import useUrlMethods from './use/url-methods';
import useBalanceAccountQuery from './queries/balance-account-query';
import useFormatCurrency from './use/format-currency';
import TheHeaderShippingAddressSelector from './the-header-shipping-address-selector.vue';
import useAddressesQuery from './queries/addresses-query';
import useShopQuery from './queries/shop-query';
import useProductWholesalePriceMethods from './use/product-wholesale-price';
import theHeaderTopMessages from './the-header-top-messages.vue';

const isMobile = inject('isMobile');

const props = defineProps<{
  user?: User,
}>();

const sessionStore = useSessionStore();
sessionStore.$patch((state) => {
  if (props.user?.id) {
    state.user = props.user;
  }
});

const menuStore = useGeneralMenuStore();
const cartStore = useCartStore();
const potentialMakerContactStore = usePotentialMakerContactStore();
const isSuperAdmin = computed(() => sessionStore.user?.kind === 'super_admin');

const { getQueryParam } = useUrlMethods();
const makerReferred = computed(() => getQueryParam('referred'));
const discountCode = computed(() => getQueryParam('discount_code'));
const shopReference = computed(() => getQueryParam('reference'));
const signUpStore = useSignUpStore();

const isShopUser = computed(() => sessionStore.user?.id && sessionStore.user?.kind === 'shop');

function manageShowMenu() {
  menuStore.showCartSidebar = false;
  menuStore.showMakerFilterSidebar = false;
  menuStore.showMakerAdminMenu = false;
  menuStore.showShopAdminMenu = false;
  menuStore.showProductFilterSidebar = false;
  menuStore.showMenu = !menuStore.showMenu;
}

function manageShowCartSidebar() {
  menuStore.showMenu = false;
  menuStore.showMakerFilterSidebar = false;
  menuStore.showMakerAdminMenu = false;
  menuStore.showShopAdminMenu = false;
  menuStore.showProductFilterSidebar = false;
  menuStore.showCartSidebar = !menuStore.showCartSidebar;
}

watch(() => makerReferred.value, () => {
  signUpStore.userForm.referredSlug = makerReferred.value;
  sessionStore.referredSlug = makerReferred.value;
});

watch(() => discountCode, () => {
  signUpStore.shopForm.registrationDiscountCodeToken = discountCode.value;
});

const cartProductsQueryEnabled = computed(() => !!sessionStore.user?.id);
const cartProductsQuery = useQuery(
  ['cart-products', sessionStore.user?.id], () => cartProductsApi.index(), {
    enabled: cartProductsQueryEnabled,
    onSuccess: (data) => {
      const cartProducts = data.data.cartProducts as CartProduct[];
      cartProducts.forEach((cartProduct) => {
        if (cartStore.items[cartProduct.productId]) {
          // if exists
        } else {
          productsApi.get(cartProduct.productId).then((response) => {
            const product = response.data.product as Product;
            const { productWholesalePrice } = useProductWholesalePriceMethods(
              product, computed(() => cartProduct.quantity));
            cartStore.items[cartProduct.productId] = {
              product,
              quantity: cartProduct.quantity,
              wholesalePriceCents: productWholesalePrice.value,
            };
          });
        }
      });
    },
  });

function loadRegisterialog() {
  if (makerReferred.value && !sessionStore.user?.id && !window.location.pathname.includes('users')) {
    menuStore.showUserRegisterDialog = true;
    menuStore.initialValueSignIn = false;
    signUpStore.userForm.referredSlug = makerReferred.value;
    signUpStore.shopForm.registrationDiscountCodeToken = discountCode.value;
  }
}

const currentPathIncludesOrdersCheckout = computed(() => window.location.pathname.includes('orders/checkout'));
const isLoadingShopOrAddress = ref(false);
const { shop, shopQuery } = useShopQuery(computed(() => sessionStore.user?.shopId));
const {
  addresses, addressesQuery: newAddressesQuery,
} = useAddressesQuery(computed(() => sessionStore.user?.shopId), computed(() => 'Shop'),
  computed(() => !!sessionStore.user?.shopId));

function userWithReferenceRegionAndCommune() {
  return (sessionStore.user?.shopId && shop.value?.referenceRegionId && shop.value?.referenceCommuneId);
}

function userWithAddress() {
  return (sessionStore.user?.shopId && addresses.value?.length && addresses.value?.length > 0);
}

const isShopUserWithoutCompleteShop = computed(() => {
  if (!sessionStore.user?.id) return false;
  if (sessionStore.user?.kind !== 'shop') return false;
  if (userWithReferenceRegionAndCommune()) return false;
  if (userWithAddress()) return false;

  return true;
});

async function redirectToShopsSignUpOrLoadShopAndAddresses() {
  if (sessionStore.user?.id && sessionStore.user?.kind === 'shop' && sessionStore.user?.shopId) {
    isLoadingShopOrAddress.value = true;
    await Promise.all([shopQuery.suspense(), newAddressesQuery.suspense()]);
    isLoadingShopOrAddress.value = false;
  }
  if (isShopUserWithoutCompleteShop.value && !signUpStore.currentPathIncludesShopsSignUp) {
    signUpStore.currentStep = 'shop';
    window.location.href = '/shops/sign_up';
  }
}

function isUserPresentAndShopKind() {
  return sessionStore.user?.id && sessionStore.user?.kind === 'shop' && !isSuperAdmin.value;
}

async function shopAndAddressesUpdate() {
  await Promise.all([shopQuery.refetch.value(), newAddressesQuery.refetch.value()]);
}

onMounted(async () => {
  if (props.user?.id) sessionStore.reloadUser();
  loadRegisterialog();
  if (makerReferred.value) sessionStore.referredSlug = makerReferred.value;
  if (shopReference.value) potentialMakerContactStore.referenceSlug = shopReference.value;
  if (cartProductsQueryEnabled.value) await cartProductsQuery.suspense();
  if (isUserPresentAndShopKind()) redirectToShopsSignUpOrLoadShopAndAddresses();
});

const {
  balanceAccount, isLoading: isBalanceAccountLoading } = useBalanceAccountQuery(computed(() => props.user?.shopId));

const headerRef = ref(null);
watch(headerRef, () => {
  menuStore.headerRef = headerRef.value;
});
</script>
