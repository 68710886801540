<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Información de marca</span>
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full bg-white rounded-md md:py-5 md:px-10">
      <div class="flex flex-col col-span-1 items-center mb-5 md:flex-row md:space-x-5">
        <img
          :src="maker.logoImage?.derivatives['jpgMd']?.url || greenKImage"
          class="object-cover shrink-0 w-40 h-40 rounded-full"
        >
        <div class="flex flex-col items-center text-center md:items-start md:text-left">
          <h1 class="mb-3 font-serif text-2xl text-gray-700">
            {{ maker.name }}
          </h1>
          <h2 class="mb-2 text-sm font-light text-gray-500 md:text-base">
            Maneja la información de tus productos, pedidos, tiendas y más.
          </h2>
          <a
            :href="`/makers/${maker.slug}`"
            class="text-sm font-light text-gray-500 hover:underline"
            target="_blank"
          >
            Ver perfil público.
          </a>
        </div>
      </div>
      <div class="flex flex-col mb-5 w-full text-gray-700">
        <div class="flex justify-between items-center mb-1 w-full">
          <p class="text-lg font-semibold md:text-xl">
            Información pública
          </p>
          <button
            class="text-sm text-gray-700 hover:underline md:text-base"
            @click="isEdit = !isEdit"
          >
            {{ isEdit ? 'Volver' : 'Editar' }}
          </button>
        </div>
        <div class="mb-3 border-b border-gray-500" />
        <maker-account-edit
          v-if="isEdit"
          :maker="maker"
          @maker-updated="makerUpdated"
        />
        <maker-account-show
          v-else
          :maker="maker"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import greenKImage from 'assets/images/green-k.jpg';
import MakerAccountEdit from './maker-account-edit.vue';
import MakerAccountShow from './maker-account-show.vue';

interface MakerAccountDetailsProps {
  maker: Maker;
}

withDefaults(defineProps<MakerAccountDetailsProps>(), {
});

const emit = defineEmits<{(event: 'refetch-maker'): void}>();

const isEdit = ref(false);

function makerUpdated() {
  isEdit.value = false;
  emit('refetch-maker');
}
</script>
