<template>
  <div
    v-if="menuStore.showMenu"
    class="fixed inset-0 z-20 bg-gray-800/50"
    aria-hidden="true"
    @click="menuStore.showMenu = false"
  />
  <transition
    enter-active-class="transition-transform duration-300"
    enter-from-class="-translate-x-full sm:translate-x-full"
    enter-to-class="translate-x-0"
    leave-active-class="transition-transform duration-300"
    leave-from-class="translate-x-0"
    leave-to-class="-translate-x-full sm:translate-x-full"
  >
    <nav
      v-if="menuStore.showMenu"
      id="sidebar"
      class="flex fixed left-0 z-30 flex-col w-3/5 h-full text-sm bg-white md:right-0 md:left-auto md:w-60 md:text-base"
    >
      <div class="flex sticky top-0 flex-col w-full">
        <a
          v-if="isMobile"
          class="py-3 px-4 hover:text-white hover:bg-lk-green md:py-4 md:px-5"
          href="/"
        >
          Inicio
        </a>
        <a
          v-if="isMobile"
          class="py-3 px-4 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
          href="/makers"
        >
          Marcas
        </a>
        <a
          v-if="isMobile"
          class="py-3 px-4 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
          href="/categories"
        >
          Categorías
        </a>
        <a
          v-if="isMobile"
          class="py-3 px-4 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
          href="/products"
        >
          Productos
        </a>
        <a
          v-if="isMobile"
          class="py-3 px-4 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
          href="/about_us"
        >
          Sobre Nosotros
        </a>
        <a
          v-if="sessionStore.user?.id && sessionStore.user?.shopId"
          class="py-3 px-4 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
          href="/shops/account"
        >
          Mi tienda
        </a>
        <a
          v-if="sessionStore.user?.id && sessionStore.user?.makerId"
          class="py-3 px-4 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
          href="/makers/account"
        >
          Mi cuenta
        </a>
        <a
          v-if="sessionStore.user?.id && sessionStore.user?.shopId"
          href="/shops/account?tab=unpaidOrders"
          class="py-3 px-4 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
        >
          Pendiente de pago
        </a>
        <a
          v-if="sessionStore.user?.id && sessionStore.user?.shopId"
          class="py-3 px-4 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
          href="/shops/account?tab=orders"
        >
          Ordenes
        </a>
        <a
          v-if="sessionStore.user?.id && sessionStore.user?.makerId"
          class="py-3 px-4 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
          href="/makers/account?tab=refer"
        >
          Invita a tiendas
        </a>
        <button
          v-if="!sessionStore.user?.id"
          class="py-3 px-4 text-left hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
          @click="menuStore.openUserRegisterDialog(true)"
        >
          Iniciar Sesión
        </button>
        <button
          v-if="!sessionStore.user?.id"
          class="py-3 px-4 text-left hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4 md:px-5"
          @click="menuStore.openUserRegisterDialog(false)"
        >
          Crear Cuenta
        </button>
        <div
          v-if="sessionStore.user?.id"
          class="flex fixed bottom-0 flex-col w-3/5 border-t border-gray-300 md:w-60"
        >
          <div class="flex justify-center items-center py-3 w-full md:py-4 md:px-5">
            <UserCircleIcon class="mr-2 w-8 h-8 text-gray-700" />
            <p class="flex flex-col text-sm">
              <span>{{ sessionStore.user?.firstName }}</span>
              <span
                v-if="sessionStore.user?.id && sessionStore.user?.shopId"
                class="text-xs font-light"
              >
                {{ shop?.name }}
              </span>
              <span
                v-if="sessionStore.user?.id && sessionStore.user?.makerId"
                class="text-xs font-light"
              >
                {{ maker.name }}
              </span>
            </p>
          </div>
          <button
            v-if="sessionStore.user?.id"
            class="py-3 px-5 w-full text-sm text-center hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4"
            @click="resetStoresAndSignOut"
          >
            Salir
          </button>
        </div>
      </div>
    </nav>
  </transition>
</template>
<script lang="ts" setup>
import { inject, computed } from 'vue';
import { UserCircleIcon } from '@heroicons/vue/24/solid';
import useGeneralMenuStore from 'stores/general-menu-store';
import useSessionStore from 'stores/user-store';
import useCartStore from 'stores/cart';
import useSignUpStore from 'stores/sign-up-store';
import useShopQuery from './queries/shop-query';
import useMakerQuery from './queries/maker-query';

const isMobile = inject('isMobile');

const menuStore = useGeneralMenuStore();
const sessionStore = useSessionStore();
const cartStore = useCartStore();
const signUpStore = useSignUpStore();

const shopId = computed(() => sessionStore.user?.shopId);
const { shop } = useShopQuery(shopId);
const makerId = computed(() => sessionStore.user?.makerId);
const { maker } = useMakerQuery(makerId);

function resetStoresAndSignOut() {
  sessionStore.reset();
  cartStore.reset();
  signUpStore.reset();
  window.location.href = '/users/sign_out';
}

</script>
